import Experience from './ThreeJsHome/Experience.js'

let globalScale = 10
let fakeScrollY = 0
let fakeScrollYNom = 0

let startY = 0
let startX = 0
let velTouchY = 0
let maxScroll



document.querySelector("#activeItem").addEventListener('click', function () {
  if (window.innerWidth > 750) return
  var dropdownContent = document.getElementById("dropdownContent");
  let deItem = document.querySelector('#activeItem')
  if (dropdownContent.style.display === 'flex') {
    dropdownContent.style.display = 'none';
  } else {
    dropdownContent.style.display = 'flex';
    dropdownContent.style.top =  - 4 + 'px';
    dropdownContent.style.left = - deItem.getBoundingClientRect().width + 'px';
    dropdownContent.style.height = deItem.getBoundingClientRect().height + 'px';
    dropdownContent.style.width = deItem.getBoundingClientRect().width + 'px';
  }
});

document.addEventListener("DOMContentLoaded", function () {
  function scaleText() {
    const baseSize = 10;
    let scaleFactor = 0;
    let newSize = 0;

    if (window.innerWidth > 1200) {
      scaleFactor = Math.max(Math.min(- (window.innerWidth - 1920) * 0.005, 6.5), -5)
    } else {
      scaleFactor = Math.min(- (window.innerWidth - 1500) * 0.005, 7)
    }
    // console.log(scaleFactor)
    newSize = baseSize - scaleFactor;
    globalScale = newSize
    document.querySelector('html').style.fontSize = newSize + 'px';
    if (window.innerWidth / window.innerHeight > 1.45) {
      document.querySelector('#visionpage').style.height = window.innerHeight + (window.innerWidth / window.innerHeight - 1) * 300 + 'px';
    } else {
      document.querySelector('#visionpage').style.height = window.innerHeight + 'px';
    }
    if(window.innerWidth < 1200 && window.innerWidth > 750) document.querySelector('#projectpage').style.height = window.innerHeight + (1500 - window.innerWidth) * 1 - (1200 - window.innerWidth) * 0.5 + 'px';
    else document.querySelector('#projectpage').style.height = window.innerHeight
  }

  // Call scaleText on page load
  scaleText();

  // Add event listener to scale text on window resize
  window.addEventListener('resize', scaleText);
});


let disableCircle = false

const experience = new Experience(document.querySelector('canvas.webgl'))
let circle = document.querySelector('.circle');
document.addEventListener('mousemove', function (e) {
  if (window.innerWidth < 1200) return
  circle.classList.add('visible')
  circle.style.left = (e.pageX - 40) + 'px';
  circle.style.top = (e.pageY - 40) + 'px';
  circle.style.transform = 'scale(1)';
});

function initCircle() {
  if (window.innerWidth < 1200) {
    document.querySelector('#click-svg').classList.remove('invisible')
    circle.classList.add('visible')
    circle.style.left = ((window.innerWidth / 2) - 45) + 'px';
    circle.style.top = document.querySelector('#click-svg').getBoundingClientRect().top - circle.getBoundingClientRect().height / 2 - fakeScrollY + 'px';
    circle.style.transform = 'scale(1)';
  } else {
    document.querySelector('#click-svg').classList.add('invisible')
    circle.classList.remove('visible')
  }
}

initCircle()

function onInactivity() {
  disableCircle = false
}

let inactivityTimer = setTimeout(onInactivity, 10000);

function resetTimer() {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(onInactivity, 10000);
}

document.addEventListener('click', resetTimer);


/** 
 *  * FAKE SCROLL
 */


setTimeout(() => {
  maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + fakeScrollY - window.innerHeight
}, 200)
window.addEventListener('resize', () => {
  setTimeout(() => {
    maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + fakeScrollY - window.innerHeight
    if (fakeScrollYNom > maxScroll) fakeScrollYNom = maxScroll
    initCircle()
  }, 200)
})

let percentage = 0

document.addEventListener('wheel', (event) => {
  // event.preventDefault()
  maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + fakeScrollY - window.innerHeight
  fakeScrollYNom += event.deltaY
  console.log(fakeScrollYNom)
  if (fakeScrollYNom < 0) fakeScrollYNom = 0
  if (fakeScrollYNom > maxScroll) fakeScrollYNom = maxScroll
})

function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

console.log('0' + easeInOutQuad(0))
console.log('0.5' + easeInOutQuad(0.5))

function scrollTo(start, limit){
  if (fakeScrollYNom < limit) {
    let range = limit - start;
    let normalizedValue = (fakeScrollYNom - start) / range;

    // Apply easing function
    let easedValue = easeInOutQuad(normalizedValue);
    if (easedValue < 1) easedValue = 1

    // Calculate the new value based on easedValue, range, and max increment
    let maxIncrement = 40;
    let increment = easedValue * maxIncrement;
    fakeScrollYNom += increment
    experience.mouse.fakeScrollYNom += increment
    if (fakeScrollYNom < 0) fakeScrollYNom = 0
    if (fakeScrollYNom > maxScroll) fakeScrollYNom = maxScroll
    setTimeout(() => {
      scrollTo(start, limit)
    }, 10)
  }
}
let counterScroll = 0
document.addEventListener('keydown', (event) => {
  if (event.key === 'ArrowDown') {
    switch (counterScroll) {
      case 0:
        scrollTo(fakeScrollY, document.querySelector('#visionpage').getBoundingClientRect().top + 100 + fakeScrollY)
        counterScroll++
        break;
      case 1:
        scrollTo(fakeScrollY, document.querySelector('#projectpage').getBoundingClientRect().top  + fakeScrollY)
        counterScroll++
        break;
      case 2:
        scrollTo(fakeScrollY, document.querySelector('#usppage').getBoundingClientRect().top + 80 + fakeScrollY)
        counterScroll++
        break;
      case 3:
        scrollTo(fakeScrollY, document.querySelector('#contactpage').getBoundingClientRect().top + fakeScrollY)
        counterScroll++
        break;
    }
  }
})

document.addEventListener('touchstart', handleTouchStart, { passive: false });
document.addEventListener('touchmove', handleTouchMove, { passive: false });

function handleTouchStart(event) {
  const touch = event.touches[0];
  startY = touch.clientY;
  startX = touch.clientX;
}

function handleTouchMove(event) {
  event.preventDefault()
  maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + fakeScrollY - window.innerHeight
  const touch = event.touches[0];
  if (Math.abs(touch.clientX - startX) < Math.abs(touch.clientY - startY)) {
    const moveY = touch.clientY - startY;
    fakeScrollYNom -= moveY * 0.6;
    velTouchY = moveY
    startY = touch.clientY;
    startX = touch.clientX;
    if (fakeScrollYNom < 0) fakeScrollYNom = 0
    if (fakeScrollYNom > maxScroll) fakeScrollYNom = maxScroll
  }
}

function smoothScroll() {
  // console.log('jsMouseScroll', fakeScrollY, maxScroll)
  if (Math.abs(velTouchY) > 0.01) {
    velTouchY *= 0.95
    fakeScrollYNom -= velTouchY
    if (fakeScrollYNom > maxScroll) fakeScrollYNom = maxScroll
  }

  fakeScrollY += (fakeScrollYNom - fakeScrollY) * 0.15
  if (fakeScrollY < 5) fakeScrollY = 0
  document.getElementById('startpage').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('visionpage').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('projectpage').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('usppage').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('contactpage').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('footer').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('whitespace1').style.transform = `translateY(-${fakeScrollY}px)`
  document.getElementById('transition-text').style.transform = `translateY(-${fakeScrollY}px)`

  if (fakeScrollY > 1) {
    document.querySelector('.scrollable-content').style.cursor = 'default'
    document.getElementById('circle').classList.add('clicked')
    document.querySelector('#click-svg').classList.add('invisible')
  } else {
    document.querySelector('.scrollable-content').style.cursor = 'pointer'
    if (!disableCircle) document.getElementById('circle').classList.remove('clicked')
    else document.getElementById('circle').classList.add('clicked')
    if(window.innerWidth < 1200) document.querySelector('#click-svg').classList.remove('invisible')
  }
  percentage = fakeScrollY / maxScroll
  document.getElementById('progressbar').style.height = `${percentage * 100}%`
  requestAnimationFrame(smoothScroll)
  
}

smoothScroll()
/**
 * Scroll Animations
 */

const animation_elements = document.querySelectorAll('.animate-on-scroll');

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('animate');
    } else {
      entry.target.classList.remove('animate');
    }
  })
}, {
  threshold: 0.05
});

for (let i = 0; i < animation_elements.length; i++) {
  const el = animation_elements[i];

  observer.observe(el);
}

// Navbar
let activeSite = 1; // 1 = Home, 2 = About, 3 = Projects, 4 = Contact
let menuOpen = false;
let menuInTransition = false;

let line, lineOffset, navBox, navBoxStyle, navBoxItem, navBoxItemHeight, offset
let wordContainer, word, coloredWord

function setVariables() {
  line = document.getElementById('movingNavLine')
  lineOffset = line.getBoundingClientRect().top + line.getBoundingClientRect().height / 2
  navBox = document.querySelector('.navbox')
  navBoxStyle = window.getComputedStyle(navBox)
  navBoxItem = document.querySelector('.navbox__item')
  navBoxItemHeight = 5.4 * globalScale //  ! Set manually, because div is not rendered yet and getBoundingClientRect() returns 0
  offset = navBox.getBoundingClientRect().top + 5 + parseInt(navBoxStyle.getPropertyValue('padding'), 10) + activeSite * navBoxItemHeight - navBoxItemHeight / 2 - lineOffset
}

// Word Coloring
function setupWordColoring() {
  // Animate the Text 
  wordContainer = document.querySelector('.navbox__link.active');
  word = wordContainer.innerText;
  wordContainer.innerHTML = '';
  coloredWord = [...word].map((letter, index, arr) => {
    return `<span style="transition-delay:${(arr.length - index - 1) * 0.05}s;">${letter}</span>`;
  }).join('');
  wordContainer.innerHTML = coloredWord;
}

function colorWord(color) {
  setTimeout(() => {
    wordContainer.querySelectorAll('span').forEach(span => {
      span.style.color = `#${color}`;
    });
  }, 600);
}

// Set correct Active Item 
document.querySelectorAll('.navbox__link').forEach((link, index) => {
  if (index + 1 === activeSite) {
    link.classList.add('active')
  }
})

// Functions for moving middle line
function moveMiddleLineOpen() {
  line.classList.add('open')
  line.style.transition = 'all 0.3s ease'
  line.style.transform = 'rotate(90deg)'
  setTimeout(() => {
    line.style.transform = `rotate(90deg) translateX(${offset + 'px'}) scale(1.5)`
  }, 300)
  setTimeout(() => {
    line.style.transform = `rotate(90deg) translateX(${offset + 'px'}) scale(1.5) `
  }, 600)
  document.body.addEventListener('click', eventForMenu)
}

function moveMiddleLineClose() {

  line.style.transition = 'all 0.3s ease'
  line.style.transform = `rotate(90deg) translateX(${offset + 'px'}) scale(1.5) `
  setTimeout(() => {
    line.style.transform = `rotate(90deg) translateX(0px) scale(1)`
  }, 100)
  setTimeout(() => {
    line.style.transform = 'rotate(0deg)'
  }, 400)
  setTimeout(() => {
    line.classList.remove('open')
  }, 600)
  document.body.removeEventListener('click', eventForMenu)
}

function eventForMenu() {
  if (menuInTransition) return
  menuInTransition = true

  if (menuOpen) {
    menuOpen = false
    moveMiddleLineClose()
    colorWord('000000')
  } else {
    menuOpen = true
    setVariables()
    moveMiddleLineOpen(activeSite)
    setupWordColoring()
    colorWord('6763ff')
  }

  // Open Menubox
  document.querySelector('.navbox').classList.toggle('active');
  document.querySelector('.navsymbol__line.line1').classList.toggle('active');
  document.querySelector('.navsymbol__line.line2').classList.toggle('active');
  document.querySelector('.navsymbol__line.line3').classList.toggle('active');

  // Prevent multiple clicks
  setTimeout(() => {
    menuInTransition = false
  }, 600)
}

document.querySelector('.navsymbol').addEventListener('click', () => {
  eventForMenu()
});

function scrollToContact() {
  if ((fakeScrollYNom + 100) < Math.min(document.querySelector('#contactpage').getBoundingClientRect().top + fakeScrollY, maxScroll)) {
    fakeScrollYNom += 100
    experience.mouse.fakeScrollYNom += 100
    setTimeout(scrollToContact, 10)
  }
}

document.getElementById('contact__button').addEventListener('click', () => {
  eventForMenu()
  scrollToContact()
});

document.getElementById('contact_nav').addEventListener('click', () => {
  scrollToContact()
});

document.addEventListener('click', (event) => {
  disableCircle = true
})