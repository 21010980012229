import GUI from 'lil-gui'

export default class Debug
{
    constructor(active)
    {
        this.active = active

        if(this.active)
        {
            this.ui = new GUI()
        }
    }
}