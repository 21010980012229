import * as THREE from 'three'
import gsap from 'gsap'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js'

export default class BusinessCard {
    constructor(experience) {
        this.experience = experience
        this.resources = this.experience.resources
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.mouse = this.experience.mouse
        this.camera = this.experience.camera
        this.debug = this.experience.debug

        this.front = this.resources.items.businessCardFront
        this.back = this.resources.items.businessCardBack

        
        this.angle = 0
        this.inflip = false
        this.rayCaster = new THREE.Raycaster()

        this.lastMouseX = 0;
        this.lastMouseY = 0;
        this.rotateY = 0;
        this.rotateX = 0;
        this.backNormal = true
        this.startup = 0
        this.startupReturn = 0
        this.threejsWidth = 0
        this.threejsHeight = 0

        this.timeline = gsap.timeline({ paused: true })

        this.angle = 0

        this.activeSize = 0

        this.scaleFactorGui = 1

        this.init()
    }

    init() {
        if (document.visibilityState === 'visible') {
            this.createCard()
            this.determineWidth()
            this.resize()

            this.sizes.on('resize', () => {
                setTimeout(() => {
                    this.determineWidth()
                    this.resize()
                }, 100)
            })


            document.querySelector('.scrollable-content').addEventListener('mousedown', () => {
                if (this.sizes.width > 1200) {
                    this.dragRotateCardStart()
                    // console.log('start-drag')
                } else {
                    this.rayCaster.setFromCamera({ x: this.mouse.normX, y: this.mouse.normY }, this.camera.instance)
                    const intersects = this.rayCaster.intersectObjects([this.cardGroup])
                    if (intersects.length === 0) return
                    this.flipCard()
                    // console.log('flip')
                }
            })

            document.querySelector('.scrollable-content').addEventListener('mouseup', () => {
                this.isDragging = false
            })
        } else {
            setTimeout(() => {
                this.init()
            }, 100)
        }
    }

    resize() {
        if (this.sizes.width <= 1200 || (this.sizes.width / this.sizes.height) < 1.262) {
            this.positionCard1200()
        } else {
            this.positionCard1920()
        }
    }

    getViewBounds(camera, distance) {
        const vFov = camera.fov * Math.PI / 180;
        const height = 2 * distance * Math.tan(vFov / 2);
        const width = height * camera.aspect;

        return {
            width: Math.abs(width),
            height: Math.abs(height)
        };
    }

    determineWidth() {
        let bounds = this.getViewBounds(this.camera.instance, 12)
        this.threejsWidth = bounds.width
    }

    createCard() {
        this.cardGroup = this.resources.items.card.scene
        this.cardGroup.scale.set(3.5, 3.5, 3.5)
        this.cardGroup.rotation.x = Math.PI / 2
        this.cardGroup.rotation.y = Math.PI / 7

        this.cardGroup.traverse((child) => {
            if (child.isMesh) {
                child.material.map.minFilter = THREE.LinearFilter;
            }
        })
        // console.log(this.cardGroup)
        this.scene.add(this.cardGroup)

        const dummyScene = new THREE.Scene();
        const dummyCamera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const dummyGeometry = new THREE.BoxGeometry();
        const dummyMesh = new THREE.Mesh(dummyGeometry, this.cardGroup.children[0].children[0].material);
        const dummyMesh2 = new THREE.Mesh(dummyGeometry, this.cardGroup.children[0].children[1].material);


        dummyScene.add(dummyMesh, dummyMesh2);


        this.experience.renderer.instance.setSize(1, 1);
        this.experience.renderer.instance.render(dummyScene, dummyCamera);


        this.experience.renderer.instance.setSize(this.sizes.width, this.sizes.height);

    }

    positionCard1920() {
        var box = new THREE.Box3().setFromObject(this.cardGroup);
        let bounds = this.getViewBounds(this.camera.instance, 12)

        let scaleFactor = bounds.width / 6
        this.cardGroup.scale.set(scaleFactor, scaleFactor, scaleFactor)

        document.querySelector('#visioncontainer').style.width = '50vw'

        setTimeout(() => {
            let yFactor
            yFactor = ((document.querySelector('#startpage').getBoundingClientRect().height / this.sizes.height) / 2 + (document.querySelector('#whitespace1').getBoundingClientRect().height / this.sizes.height) + 0.5 * (document.querySelector('#visionpage').getBoundingClientRect().height / this.sizes.height)) * -10 + 3
            this.cardGroup.position.set(-4 - bounds.width / 4, yFactor, 1)
            this.cardGroup.rotation.x = Math.PI / 2
            this.cardGroup.rotation.y = Math.PI / 7
            this.cardGroup.rotation.z = 0
        }, 100)


    }

    getViewBounds(camera, distance) {
        const vFov = camera.fov * Math.PI / 180;
        const height = 2 * distance * Math.tan(vFov / 2);
        const width = height * camera.aspect;
        // console.log('width: ', Math.abs(width), 'height: ', Math.abs(height))
        return {
            width: Math.abs(width),
            height: Math.abs(height)
        };
    }

    positionCard1200() {
        let bounds = this.getViewBounds(this.camera.instance, (12))

        let scaleFactor = (this.sizes.width - this.sizes.height * 0.5) > 0 ? bounds.width / 2 - (this.sizes.width - this.sizes.height * 0.5) * 0.002 : bounds.width / 2
        this.cardGroup.scale.set(scaleFactor, scaleFactor, scaleFactor)
        let yFactor

        setTimeout(() => {
            var box = new THREE.Box3().setFromObject(this.cardGroup);
            var size = new THREE.Vector3();
            box.getSize(size);

            let textHeight = document.querySelector('#visioncontainer').getBoundingClientRect().height
            document.querySelector('#visioncontainer').style.width = (size.x / bounds.width * 100) + 'vw'
            document.querySelector('#visioncontainer').style.maxwidth = (size.x / bounds.width * 100) + 'vw'
            textHeight = document.querySelector('#visioncontainer').getBoundingClientRect().height
            yFactor = (((document.querySelector('#startpage').getBoundingClientRect().height / this.sizes.height) / 2 + (document.querySelector('#whitespace1').getBoundingClientRect().height / this.sizes.height) + (document.querySelector('#visionpage').getBoundingClientRect().height / this.sizes.height)) * this.sizes.height - textHeight) / this.sizes.height * -10 + 3 + size.y * 0.5 + 0.1
            this.cardGroup.position.set(-4, yFactor + 0.2, 1)
        }, 100)
        // }


        this.cardGroup.rotation.x = Math.PI / 2
        this.cardGroup.rotation.y = 0
        this.cardGroup.rotation.z = 0
        // this.timeline = gsap.timeline({ paused: true })
        // this.timeline.to(this.cardGroup.rotation, { x: Math.PI / 2, duration: 1, ease: "power2.inOut" })   

    }

    flipCard() {
        if (this.inflip) return
        this.inflip = true
        let z = this.cardGroup.position.z
        if (this.angle === 0) this.angle = Math.PI
        else this.angle = 0
        gsap.to(this.cardGroup.position, {
            z: z + 0.5,
            duration: 0.3,
            ease: 'power2.inOut'
        }).then(() => {
            gsap.to(this.cardGroup.rotation, {
                z: this.angle,
                duration: 0.8,
                ease: 'power2.inOut'
            }).then(() => {
                gsap.to(this.cardGroup.position, {
                    z: z,
                    duration: 0.2,
                    ease: 'power2.inOut'
                })
            })
        })
        setTimeout(() => {
            this.inflip = false
        }, 1300)

    }

    dragRotateCardStart() {
        this.rayCaster = new THREE.Raycaster()
        this.rayCaster.setFromCamera({ x: this.mouse.normX, y: this.mouse.normY }, this.camera.instance)
        const intersects = this.rayCaster.intersectObjects([this.cardGroup])
        if (intersects.length === 0) return
        this.isDragging = true
        this.startMouse = { x: this.mouse.x, y: this.mouse.y }
        this.startRotation = { x: this.cardGroup.rotation.x, y: this.cardGroup.rotation.y }
        this.lastMouseX = this.mouse.x;
        this.lastMouseY = this.mouse.y;
        this.dragRotateCard()
    }

    dragRotateCard() {
        this.backNormal = false
        this.rotationSpeed = 2

        this.deltaX = this.mouse.x - this.lastMouseX;
        this.deltaY = this.mouse.y - this.lastMouseY;

        this.rotateY = this.deltaX * this.rotationSpeed;
        this.rotateX = this.deltaY * this.rotationSpeed;


        this.lastMouseX = this.mouse.x;
        this.lastMouseY = this.mouse.y;

        if (this.isDragging) {
            setTimeout(() => {
                this.dragRotateCard()
            }, 1000 / 60)
        }

    }


    update() {
        // Timeline
        let progress = (this.mouse.scrollPos - 100) / this.sizes.height
        if (this.sizes.with >= 1200) {
            if (progress > 1) progress = 1
            this.timeline.progress(progress)
            // console.log(progress)
        } else {
            progress = (this.mouse.scrollPos) / this.sizes.height
            this.timeline.progress(progress)
        }

        if (this.sizes.width <= 1200) return
        if (!this.isDragging) {
            this.rotateY *= 0.92;
            this.rotateX *= 0.92;

            if (Math.abs(this.rotateX) <= 0.002) {
                this.rotateX = 0
            }
            if (Math.abs(this.rotateY) <= 0.002) {
                this.rotateY = 0
            }
        }

        if (Math.abs(this.rotateX) === 0 && Math.abs(this.rotateY) === 0 && !this.isDragging && !this.backNormal) {
            if (this.startupReturn < 0.02) this.startupReturn += 0.0005
            this.cardGroup.rotation.x -= (this.cardGroup.rotation.x - Math.PI / 2) * this.startupReturn
            this.cardGroup.rotation.y -= (this.cardGroup.rotation.y - Math.PI / 7) * this.startupReturn
            this.cardGroup.rotation.z -= this.cardGroup.rotation.z * this.startupReturn

            if ((Math.abs(this.cardGroup.rotation.x) - Math.PI / 2) < 0.01 && (Math.abs(this.cardGroup.rotation.y) - Math.PI / 7) < 0.01 && Math.abs(this.cardGroup.rotation.z) < 0.01) {
                this.backNormal = true
            }
        } else {
            this.startupReturn = 0
        }

        this.cardGroup.rotation.y += this.rotateY * (this.experience.time.delta / 0.016);
        this.cardGroup.rotation.x += this.rotateX * (this.experience.time.delta / 0.016);
        if (progress >= 1) {
            if (Math.abs(this.rotateX) === 0 && Math.abs(this.rotateY) === 0 && !this.isDragging && this.backNormal) {
                if (this.startup < 0.3) this.startup += 0.002
                var diagonal = new THREE.Vector3(1.74, 0.004, -1).normalize();
                this.angle = this.startup * Math.PI / 180;
                this.cardGroup.rotateOnAxis(diagonal, this.angle * (this.experience.time.delta / 0.016));
            } else {
                this.startup = 0
            }
        }

    }
}