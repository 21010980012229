import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { gsap } from "gsap";

export default class Camera {
    constructor(_experience, _controlsActive) {

        this.experience = _experience
        this.controlsActive = _controlsActive
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.mouse = this.experience.mouse

        this.zValue = { value: 0 }
        this.yValue = { value: 0 }
        this.xValue = { value: 0 }
        this.zRotation = { value: 0 }
        this.xRotation = { value: 0 }

        this.positionSave = { x: 0, y: 0, z: 0 }
        this.smoothScroll = 0

        this.yStep = 18

        this.renderComplete = false

        this.setInstance()

        if (this.controlsActive) {
            this.setContols()
        }
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(45, this.sizes.width / this.sizes.height, 1, 45);
        this.instance.position.set(-4, 3, 13 + this.zValue.value)
        this.scene.add(this.instance);
       
    }

    setContols() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
        if (this.sizes.width <= 500) {
            // this.instance.fov = (this.sizes.height / this.sizes.width) * 21
        }
    }

    updateCameraPos1920() {
        this.instance.position.set(
            -4 + (this.mouse.normXSmooth) * 0.2 * 0 + this.xValue.value,
            3 - (this.mouse.scrollPos / this.sizes.height) * 10 + (this.mouse.normYSmooth) * 0.2 * 0 + this.yValue.value,
            13 + this.zValue.value
        );


        // console.log(this.instance.position)
    }

    updateCameraBentos() {
        this.instance.position.set(
            -4 + (this.mouse.normXSmooth) * 0.2 * 0 + this.xValue.value,
            3 - ((this.mouse.scrollPos + (document.querySelector('#usppage').getBoundingClientRect().top -200 ) * 0.2) / this.sizes.height) * 10 + (this.mouse.normYSmooth) * 0.2 * 0 + this.yValue.value,
            13 + this.zValue.value
        );
    
    }

    updateCameraPreload() {
        this.instance.position.set(
            -4, 
            3 - this.experience.time.elapsed * 0.06,
            13
        )
    }

    update() {
        if (this.controlsActive) {
            this.controls.update()
        }

        if (this.experience.time.elapsed < 1000) {
            this.updateCameraPreload()
            // console.log(this.experience.time.elapsed)
        } else if (this.renderComplete) {
            if (document.querySelector('#usppage').getBoundingClientRect().top < 200 && (this.sizes.width / this.sizes.height) < 0.83){
                this.updateCameraBentos()
            } else {
                this.updateCameraPos1920()
            }
        }
    }


}