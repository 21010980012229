export default [
    {
        name: 'tailored3D',
        type: 'texture',
        path: '/textures/DE/maßgeschneidert.png'
    },
    {
        name: 'optimized',
        type: 'texture',
        path: '/textures/DE/optimierung.png'
    },
    {
        name: 'interactive',
        type: 'texture',
        path: '/textures/DE/interaktive_ux.png'
    },
    {
        name: 'responsive',
        type: 'texture',
        path: '/textures/DE/funktionales_design.png'
    },
    {
        name: 'technical',
        type: 'texture',
        path: '/textures/DE/moderne_technische.png'
    },
    {
        name: 'threed_modelling',
        type: 'texture',
        path: '/textures/DE/3d_modellierung.png'
    },
    {
        name: 'insight',
        type: 'texture',
        path: '/textures/DE/angemessene.png'
    },

    {
        name: 'ballMatcap',
        type: 'texture',
        path: '/textures/ball3.png'
    },
    {
        name: 'businessCardFront',
        type: 'texture',
        path: '/textures/frontside_2.png'
    },
    {
        name: 'businessCardBack',
        type: 'texture',
        path: '/textures/backside_2.png'
    },
    {
        name: 'serviceFont',
        type: 'font',
        path: '/fonts/Onest SemiBold_Regular.json'
    },
    {
        name: 'card',
        type: 'gltfModel',
        path: 'card_EN.glb'
    },
    {
        name: 'insight_mobile',
        type: 'texture',
        path: '/textures/mobiles/insight_driven_mobile.png'
    },
    {
        name: 'transparent_mobile',
        type: 'texture',
        path: '/textures/mobiles/transparent_price_mobile.png'
    },
    {
        name: 'technical_expertise_mobile',
        type: 'texture',
        path: '/textures/mobiles/technical_expertise_mobile.png'
    },
    {
        name: 'responsive_mobile',
        type: 'texture',
        path: '/textures/mobiles/responsive_mobile.png'
    },
    {
        name: 'optimized_mobile',
        type: 'texture',
        path: '/textures/mobiles/optimization_mobile.png'
    },
    {
        name: 'tailored3D_mobile',
        type: 'texture',
        path: '/textures/mobiles/tailored3D_mobile.png'
    }, 

    // Projektbeispiele 

    {
        name: 'gradientTexture3',
        type: 'texture',
        path: '/textures/5.jpg'
    },
    {
        name: 'gradientTexture5',
        type: 'texture',
        path: '/textures/3.jpg'
    },
    {
        name: 'modelling',
        type: 'texture',
        path: '/textures/projektBeispiele/3D Modelling.png'
    },
    {
        name: 'advertisment',
        type: 'texture',
        path: '/textures/projektBeispiele/Advertisment.png'
    },
    {
        name: 'custom',
        type: 'texture',
        path: '/textures/projektBeispiele/Custom 3D Solutions.png'
    },
    {
        name: 'educational',
        type: 'texture',
        path: '/textures/projektBeispiele/Educational Content.png'
    }, 
    {
        name: 'portfolio',
        type: 'texture',
        path: '/textures/projektBeispiele/Portfolio.png'
    },
    {
        name: 'configurator',
        type: 'texture',
        path: '/textures/projektBeispiele/Product Configurator.png'
    },
    {
        name: 'showcase',
        type: 'texture',
        path: '/textures/projektBeispiele/Product Showcase.png'
    },
    {
        name: 'simulation',
        type: 'texture',
        path: '/textures/projektBeispiele/Simulation.png'
    },
    {
        name: 'virtual',
        type: 'texture',
        path: '/textures/projektBeispiele/Virtual Event Spaces.png'
    },

    // EN 

    {
        name: 'tailored3D_EN',
        type: 'texture',
        path: '/textures/EN/tailored3d.png'
    },
    {
        name: 'optimized_EN',
        type: 'texture',
        path: '/textures/EN/optimization.png'
    },
    {
        name: 'interactive_EN',
        type: 'texture',
        path: '/textures/EN/interactive_ux.png'
    },
    {
        name: 'responsive_EN',
        type: 'texture',
        path: '/textures/EN/responsive_design.png'
    },
    {
        name: 'technical_EN',
        type: 'texture',
        path: '/textures/EN/cutting_edge.png'
    },
    {
        name: 'threed_modelling_EN',
        type: 'texture',
        path: '/textures/EN/3d_modelling.png'
    },
    {
        name: 'insight_EN',
        type: 'texture',
        path: '/textures/EN/insight_driven.png'
    },

    // DE mobiles

    {
        name: 'insight_mobile_DE',
        type: 'texture',
        path: '/textures/mobileDE/Angemessene_Geschäftslösungen.png'
    },
    {
        name: 'transparent_mobile_DE',
        type: 'texture',
        path: '/textures/mobileDE/3D_Modellierung.png'
    },
    {
        name: 'technical_expertise_mobile_DE',
        type: 'texture',
        path: '/textures/mobileDE/Moderne_Technische_Kompetenz.png'
    },
    {
        name: 'responsive_mobile_DE',
        type: 'texture',
        path: '/textures/mobileDE/Funktionales_Design.png'
    },
    {
        name: 'optimized_mobile_DE',
        type: 'texture',
        path: '/textures/mobileDE/Optimierung.png'
    },
    {
        name: 'tailored3D_mobile_DE',
        type: 'texture',
        path: '/textures/mobileDE/Maßgeschneiderte_3D-Erlebnisse.png'
    }
]