import BusinessCard from './BusinessCard.js'
import Bentos from './Bentos.js'
import Softbody from './Softbody.js'
import FluidBalls from './FluidBalls.js'
import * as THREE from 'three'

export default class Home {
    constructor(experience) {
        this.experience = experience
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera

        this.resources.on('ready', () => {
            this.businessCard = new BusinessCard(this.experience)
            this.bentos = new Bentos(this.experience)
            this.softbody = new Softbody(this.experience)
            this.fluidballs = new FluidBalls(this.experience)
            let previousPos = this.experience.camera.position 
            this.experience.camera.instance.position.set(-4, -27, 100)
            this.experience.camera.instance.far = 1000
            this.camera.instance.updateProjectionMatrix();
            // console.log(this.camera.instance.position)
            this.experience.renderer.instance.compile(this.scene, this.experience.camera.instance)
            this.experience.camera.instance.position.set(previousPos)
            this.camera.instance.updateProjectionMatrix();
            this.experience.camera.instance.far = 45
            this.camera.renderComplete = true
        })
    }

    update() {
        if(this.businessCard) this.businessCard.update()
        if(this.bentos) this.bentos.update()
        if(this.softbody) this.softbody.update()
        if(this.fluidballs) this.fluidballs.update()
    }
}