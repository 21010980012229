import EventEmitter from "./EventEmitter"

export default class Mouse extends EventEmitter {
    constructor(experience) {
        super()
        this.experience = experience
        this.sizes = this.experience.sizes

        // Setup

        this.x = 0
        this.y = 0
        this.normX = 0
        this.normY = 0
        this.normXSmooth = 0
        this.normYSmooth = 0
        this.absX = 0
        this.absY = 0
        this.scrollPos = 0
        this.fakeScrollYNom = 0
        this.startY = 0
        this.velTouchY = 0
        this.leftToRight = 0
        this.allowJump = false
        this.allowOrientation = false
        this.orientationActive = false



        this.scrollPositions = {}
        this.scrollPositions.startpage = {}
        this.scrollPositions.visionpage = {}
        this.scrollPositions.projectpage = {}
        this.scrollPositions.usppage = {}
        this.scrollPositions.contactpage = {}

        setTimeout(() => {
            this.maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height - window.innerHeight
            this.getScrollPositions()
        }, 200)

        window.addEventListener('mousemove', (e) => {
            this.updateMouse(e)
            this.trigger('mousemove')
        })

        // document.body.addEventListener('scroll', () => {

        // })

        document.addEventListener('wheel', (event) => {
            this.maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + this.scrollPos - window.innerHeight
            this.fakeScrollYNom += event.deltaY
            if (this.fakeScrollYNom < 0) this.fakeScrollYNom = 0
            if (this.fakeScrollYNom > this.maxScroll) this.fakeScrollYNom = this.maxScroll
            // console.log(this.fakeScrollYNom)
            this.trigger('scroll')
            if (this.fakeScrollYNom > this.scrollPositions.projectpage.top) this.allowJump = true
            if (event.deltaY > 0 && this.allowJump) {
                // if (this.sizes.width >= 1400) this.experience.home.fluidballs.pushAllUp(4000)
                // else if (this.sizes.width >= 900) this.experience.home.fluidballs.pushAllUp(1500)
                // else if (this.sizes.width >= 750) this.experience.home.fluidballs.pushAllUp(1300)
                // else if (this.sizes.width >= 500) this.experience.home.fluidballs.pushAllUp(1100)
            }
        })


        this.counterScroll = 0
        

        document.addEventListener('touchstart', (event) => {
            this.handleTouchStart(event)
        }, { passive: false });

        document.addEventListener('click', () => {
            if (!this.allowOrientation) {
                if (typeof DeviceOrientationEvent.requestPermission == 'function') {
                    DeviceOrientationEvent.requestPermission()
                        .then(permissionState => {
                            if (permissionState == 'granted') {
                                window.addEventListener('deviceorientation', (event) => {
                                    this.handleOrientation(event)
                                });
                            } else {
                                console.error("Permission not granted for DeviceOrientation");
                            }
                        })
                        .catch(console.error);
                } else {
                    window.addEventListener('deviceorientation', (event) => {
                        this.handleOrientation(event)
                    });
                }
                this.allowOrientation = true
            }
        })

        document.addEventListener('touchmove', (event) => {
            this.handleTouchMove(event)
        }, { passive: false });




        this.sizes.on('resize', () => {
            setTimeout(() => {
                this.maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + this.scrollPos - window.innerHeight
                if (this.fakeScrollYNom > this.maxScroll) this.fakeScrollYNom = this.maxScroll
                this.getScrollPositions()
            }, 200)
        })
    }

    activateScroll() {
        switch (this.counterScroll) {
            case 0:
                this.scroll(document.querySelector('#visionpage').getBoundingClientRect().top + this.scrollPos)
                this.counterScroll++
                break;
            case 1:
                this.scroll(document.querySelector('#projectpage').getBoundingClientRect().top + this.scrollPos)
                this.counterScroll++
                break;
            case 2:
                this.scroll(document.querySelector('#usppage').getBoundingClientRect().top + this.scrollPos)
                this.counterScroll++
                break;
            case 3:
                this.scroll(document.querySelector('#contactpage').getBoundingClientRect().top + this.scrollPos)
                this.counterScroll++
                break;
        }
    }

    scroll(limit) {
        console.log(limit)
        if (this.fakeScrollYNom < limit) {
            this.fakeScrollYNom += 20
            this.trigger('scroll')
            if (this.fakeScrollYNom < 0) this.fakeScrollYNom = 0
            if (this.fakeScrollYNom > this.maxScroll) this.fakeScrollYNom = this.maxScroll
            setTimeout(() => {
                this.scroll(limit)
            }, 10)
        }
    }

    handleOrientation(event) {
        if (this.leftToRight != 0) this.orientationActive = true
        console.log(screen.orientation.type)
        if (screen.orientation.type == 'landscape-primary') {
            this.leftToRight = event.beta / 45;
        } else if (screen.orientation.type == 'landscape-secondary') {
            this.leftToRight = -event.beta / 45;
        } else if (screen.orientation.type == 'portrait-secondary') {
            this.leftToRight = -event.gamma / 45;
        } else {
            this.leftToRight = event.gamma / 45;
        }
    }

    handleTouchStart(event) {
        const touch = event.touches[0];
        this.startY = touch.clientY;
        this.startX = touch.clientX;
    }

    handleTouchMove(event) {
        this.maxScroll = document.querySelector('footer').getBoundingClientRect().top + document.querySelector('footer').getBoundingClientRect().height + this.scrollPos - window.innerHeight
        const touch = event.touches[0];
        if (Math.abs(touch.clientX - this.startX) < Math.abs(touch.clientY - this.startY)) {
            const moveY = touch.clientY - this.startY;
            this.fakeScrollYNom -= moveY * 0.6
            // console.log(this.fakeScrollYNom)
            this.velTouchY = moveY
            this.startY = touch.clientY;
            this.startX = touch.clientX;
            if (this.fakeScrollYNom < 0) this.fakeScrollYNom = 0
            if (this.fakeScrollYNom > this.maxScroll) this.fakeScrollYNom = this.maxScroll
            if (this.fakeScrollYNom > this.scrollPositions.projectpage.bottom) this.allowJump = true
            // console.log(this.allowJump)
            if (moveY > 0 && this.allowJump) {
                // if (this.sizes.width >= 1400) this.experience.home.fluidballs.pushAllUp(4000)
                // else if (this.sizes.width >= 900) this.experience.home.fluidballs.pushAllUp(1500)
                // else if (this.sizes.width >= 750) this.experience.home.fluidballs.pushAllUp(1300)
                // else if (this.sizes.width >= 500) this.experience.home.fluidballs.pushAllUp(1100)
            }
        }

    }

    getScrollPositions() {


        this.scrollPositions.startpage.top = document.getElementById('startpage').getBoundingClientRect().top + this.scrollPos
        this.scrollPositions.startpage.bottom = document.getElementById('startpage').getBoundingClientRect().bottom + this.scrollPos

        this.scrollPositions.visionpage.top = document.getElementById('visionpage').getBoundingClientRect().top + this.scrollPos
        this.scrollPositions.visionpage.bottom = document.getElementById('visionpage').getBoundingClientRect().bottom + this.scrollPos

        this.scrollPositions.projectpage.top = document.getElementById('projectpage').getBoundingClientRect().top + this.scrollPos
        this.scrollPositions.projectpage.bottom = document.getElementById('projectpage').getBoundingClientRect().bottom + this.scrollPos

        this.scrollPositions.usppage.top = document.getElementById('usppage').getBoundingClientRect().top + this.scrollPos
        this.scrollPositions.usppage.bottom = document.getElementById('usppage').getBoundingClientRect().bottom + this.scrollPos

        this.scrollPositions.contactpage.top = document.getElementById('contactpage').getBoundingClientRect().top + this.scrollPos
        this.scrollPositions.contactpage.bottom = document.getElementById('contactpage').getBoundingClientRect().bottom + this.scrollPos

    }

    updateMouse(e) {
        this.x = e.clientX / window.innerWidth
        this.y = e.clientY / window.innerHeight
        this.normX = (e.clientX / window.innerWidth) * 2 - 1
        this.normY = - (e.clientY / window.innerHeight) * 2 + 1
        this.normXSmooth += (this.normX - this.normXSmooth) * 0.03
        this.normYSmooth += (this.normY - this.normYSmooth) * 0.03
        this.absX = e.clientX
        this.absY = e.clientY
    }

    update() {

        if (Math.abs(this.velTouchY) > 0.01) {
            this.velTouchY *= 0.95
            this.fakeScrollYNom -= this.velTouchY
            if (this.fakeScrollYNom > this.maxScroll) this.fakeScrollYNom = this.maxScroll
        }
        this.scrollPos += (this.fakeScrollYNom - this.scrollPos) * 0.15
        if (this.scrollPos < 5) this.scrollPos = 0

        if (this.scrollPositions.projectpage.top !== undefined) {
            if (this.experience.sizes.width >= 750) {
                if (this.scrollPos > this.scrollPositions.projectpage.top - 250) this.experience.home.fluidballs.scrollActivation()
            }
            else {
                if (this.scrollPos > this.scrollPositions.projectpage.top - 200) this.experience.home.fluidballs.scrollActivation()
            }
        }
    }
}