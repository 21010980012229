import * as THREE from 'three'
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import gsap from 'gsap'
import { ConvexPolyhedron } from 'cannon';
import { zip } from 'three/examples/jsm/libs/fflate.module.js';

// Todo LOAD TEXTURES 
export default class Bentos {
    constructor(experience) {
        this.experience = experience
        this.scene = this.experience.scene
        this.renderer = this.experience.renderer
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.mouse = this.experience.mouse
        this.camera = this.experience.camera
        this.raycaster = new THREE.Raycaster()
        this.raycasterTouch = new THREE.Raycaster()
        this.ui = this.experience.debug.ui

        // Textures
        if (document.getElementById('activeItem').classList.contains('active')) {
            this.tailored3D = this.resources.items.tailored3D_EN
            this.optimized = this.resources.items.optimized_EN
            this.interactive = this.resources.items.interactive_EN
            this.threed_modelling = this.resources.items.threed_modelling_EN
            this.responsive = this.resources.items.responsive_EN
            this.insight = this.resources.items.insight_EN
            this.technical = this.resources.items.technical_EN

            //Mobile 

            this.tailored3D_mobile = this.resources.items.tailored3D_mobile
            this.optimized_mobile = this.resources.items.optimized_mobile
            this.expertise_mobile = this.resources.items.technical_expertise_mobile
            this.transparent_mobile = this.resources.items.transparent_mobile
            this.responsive_mobile = this.resources.items.responsive_mobile
            this.insight_mobile = this.resources.items.insight_mobile
        } else {
            this.tailored3D = this.resources.items.tailored3D
            this.optimized = this.resources.items.optimized
            this.interactive = this.resources.items.interactive
            this.threed_modelling = this.resources.items.threed_modelling
            this.responsive = this.resources.items.responsive
            this.insight = this.resources.items.insight
            this.technical = this.resources.items.technical

            //Mobile 

            this.tailored3D_mobile = this.resources.items.tailored3D_mobile_DE
            this.optimized_mobile = this.resources.items.optimized_mobile_DE
            this.expertise_mobile = this.resources.items.technical_expertise_mobile_DE
            this.transparent_mobile = this.resources.items.transparent_mobile_DE
            this.responsive_mobile = this.resources.items.responsive_mobile_DE
            this.insight_mobile = this.resources.items.insight_mobile_DE
        }



        this.tailored3D.minFilter = THREE.LinearFilter;
        this.optimized.minFilter = THREE.LinearFilter;
        this.interactive.minFilter = THREE.LinearFilter;
        this.threed_modelling.minFilter = THREE.LinearFilter;
        this.responsive.minFilter = THREE.LinearFilter;
        this.insight.minFilter = THREE.LinearFilter;
        this.technical.minFilter = THREE.LinearFilter;


        this.inGsap = false

        this.createBackground1920()
        this.createBoxes1920()
        this.createLights1920()
        this.positionBoxes1920()
        this.positionLights1920()

        // Todo Position based on screen size 

        this.mouse.on('mousemove', () => {
            if (this.sizes.width >= 750) {
                this.updateFlashlight1920()
            }
        })
        this.mouse.on('scroll', () => {
            if (this.sizes.width >= 750) this.updateFlashlight1920()
            else this.updateFlashlight280()
        });

        setTimeout(() => {
            // this.scene.add(textMesh);
        }, 1000)


        // Resize 
        this.resize()


        this.sizes.on('resize', () => {
            this.resize()
        })

        this.isSliding = false
        this.activeBox = 1
        this.isScroll = false
        this.startedSwipe = false
        this.touchEnded = true
        this.startY = 0
        this.startX = 0

        this.offset = 0
        this.velTouchX = 0
        this.activeEllipse = 0

        document.querySelector('.scrollable-content').addEventListener('touchstart', (event) => {
            if (this.inGsap) return
            this.startedSwipe = true
            const touch = event.touches[0];
            this.startY = touch.clientY;
            this.startX = touch.clientX;
            this.offset = Math.round(this.offset)
            if (this.isScroll) return
            this.touchX = ((event.touches[0].clientX) / this.sizes.width) * 2 - 1
            let x = ((event.touches[0].clientX) / this.sizes.width) * 2 - 1
            let y = - (((event.touches[0].clientY) / this.sizes.height) * 2 - 1)
            this.raycasterTouch.setFromCamera({ x: x, y: y }, this.camera.instance)
            const intersectTouch = this.raycasterTouch.intersectObjects([this.bento1Front, this.bento2Front, this.bento3Front, this.bento4Front, this.bento5Front, this.bento6Front], false)

            if (intersectTouch.length > 0) {
                this.isSliding = true
                this.touchStart = { x: ((event.touches[0].clientX) / this.sizes.width) * 2 - 1, y: event.touches[0].clientY }
                this.slideCard(event.touches[0])
            }
        })

        document.querySelector('.scrollable-content').addEventListener('touchmove', (event) => {
            if (this.inGsap) return
            if (this.isSliding && !this.isScroll) this.slideCard(event.touches[0])
        })

        document.querySelector('.scrollable-content').addEventListener('touchend', () => {
            if (this.inGsap) return
            if (!this.isSliding) return
            this.isSliding = false
            this.slideEnd()
            setTimeout(() => {
                this.startedSwipe = false
            }, 200)
        })

        document.querySelector('.scrollable-content').addEventListener('mousedown', (event) => {
            if (this.inGsap) return
            this.startedSwipe = true
            const touch = event;
            this.startY = touch.clientY;
            this.startX = touch.clientX;
            this.offset = Math.round(this.offset)
            if (this.isScroll) return
            this.touchX = ((event.clientX) / this.sizes.width) * 2 - 1
            let x = ((event.clientX) / this.sizes.width) * 2 - 1
            let y = - (((event.clientY) / this.sizes.height) * 2 - 1)
            this.raycasterTouch.setFromCamera({ x: x, y: y }, this.camera.instance)
            const intersectTouch = this.raycasterTouch.intersectObjects([this.bento1Front, this.bento2Front, this.bento3Front, this.bento4Front, this.bento5Front, this.bento6Front], false)

            if (intersectTouch.length > 0) {
                this.isSliding = true
                this.touchStart = { x: ((event.clientX) / this.sizes.width) * 2 - 1, y: event.clientY }
                this.slideCard(event)
            }
        })

        document.querySelector('.scrollable-content').addEventListener('mousemove', (event) => {
            if (this.inGsap) return
            if (this.isSliding && !this.isScroll) this.slideCard(event)
        })

        document.querySelector('.scrollable-content').addEventListener('mouseup', () => {
            if (this.inGsap) return
            if (!this.isSliding) return
            this.isSliding = false
            this.slideEnd()
            setTimeout(() => {
                this.startedSwipe = false
            }, 200)
        })

        document.querySelector('.left-arrow').addEventListener('click', () => {
            if (this.inGsap) return
            this.moveLeft()
        })

        document.querySelector('.right-arrow').addEventListener('click', () => {
            if (this.inGsap) return
            this.moveRight()
        })

        let scrollTimeout = null;

        const scrollEndListener = () => {
            if (scrollTimeout !== null) {
                clearTimeout(scrollTimeout);
            }
            this.isScroll = true

            scrollTimeout = setTimeout(() => {
                this.isScroll = false
            }, 150);
        };

        document.querySelector('.scrollable-content').addEventListener('scroll', scrollEndListener);

    }

    resize() {


        setTimeout(() => {
            if (this.flashLight) this.scene.remove(this.flashLight)
            if (this.flashLight) this.scene.remove(this.flashLight.target)
            if (this.pointLight) this.scene.remove(this.pointLight)
            if (this.pointLight2) this.scene.remove(this.pointLight2)
            if (this.ambientLight) this.scene.remove(this.ambientLight)
            let aspectRatio = this.sizes.width / this.sizes.height
            if (aspectRatio > 1.68) {
                this.createLights1920()
                this.remove1920Boxes()
                this.remove280Boxes()
                this.createBoxes1920()
                this.positionBoxes1920()
                this.positionLights1920()
            } else if (aspectRatio > 0.83) {
                this.createLights1920()
                this.remove1920Boxes()
                this.remove280Boxes()
                this.createBoxes1920()
                this.positionBoxes750()
                this.positionLights1920()
            } else {
                this.createLights1920()
                this.remove1920Boxes()
                this.remove280Boxes()
                this.createBoxes280()
                this.positionBoxes280()
                this.positionLights280()
            }
        }, 100)
    }

    // * 1920

    createBackground1920() {
        this.backgroundBento = new THREE.Mesh(new THREE.BoxGeometry(100, 40, 0.501, 1, 1), new THREE.MeshPhysicalMaterial({ color: 0x010101, transparent: true, opacity: 1, metalness: 0.6, roughness: 0.8 }))
        this.scene.add(this.backgroundBento)
        this.backgroundBento.receiveShadow = true
    }

    createBoxes1920() {

        const geometry = new TextGeometry('Services', {
            font: this.resources.items.serviceFont,
            size: 0.7,
            height: 0.1,
            curveSegments: 12,
            bevelEnabled: false,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelSegments: 5
        });
        const material = new THREE.MeshStandardMaterial({ color: 0xdddddd, metalness: 0.6, roughness: 0.5 })
        this.textMesh = new THREE.Mesh(geometry, material);
        this.scene.add(this.textMesh)
        this.textMesh.scale.set(1, 1, 0.05)

        this.bentoMaterial = new THREE.MeshPhysicalMaterial({ color: 0x000212, metalness: 0.6, roughness: 0.5 });
        this.bentoWidth = 18
        this.bentoHeight = 8

        this.bento1Material = new THREE.MeshStandardMaterial({ map: this.tailored3D, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento1Front = new THREE.Mesh(new THREE.PlaneGeometry(0.49 * this.bentoWidth, 0.31 * this.bentoHeight), this.bento1Material);
        this.bentoGeometryWide = new RoundedBoxGeometry(0.49 * this.bentoWidth, 0.31 * this.bentoHeight, 0.5, 3, 10);
        this.bento1 = new THREE.Mesh(this.bentoGeometryWide, this.bentoMaterial);

        this.bento2Material = new THREE.MeshStandardMaterial({ map: this.optimized, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento2Front = new THREE.Mesh(new THREE.PlaneGeometry(0.235 * this.bentoWidth, 0.31 * this.bentoHeight), this.bento2Material);
        this.bentoGeometry = new RoundedBoxGeometry(0.235 * this.bentoWidth, 0.31 * this.bentoHeight, 0.5, 3, 10);
        this.bento2 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial);

        this.bento3Material = new THREE.MeshStandardMaterial({ map: this.interactive, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento3Front = new THREE.Mesh(new THREE.PlaneGeometry(0.235 * this.bentoWidth, 0.31 * this.bentoHeight), this.bento3Material);
        this.bento3 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)

        this.bento4Material = new THREE.MeshStandardMaterial({ map: this.threed_modelling, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento4Front = new THREE.Mesh(new THREE.PlaneGeometry(0.235 * this.bentoWidth, 0.31 * this.bentoHeight), this.bento4Material);
        this.bento4 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)

        this.bento5Material = new THREE.MeshStandardMaterial({ map: this.responsive, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento5Front = new THREE.Mesh(new THREE.PlaneGeometry(0.235 * this.bentoWidth, 0.31 * this.bentoHeight), this.bento5Material);
        this.bento5 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)

        this.bento6Material = new THREE.MeshStandardMaterial({ map: this.insight, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento6Front = new THREE.Mesh(new THREE.PlaneGeometry(0.235 * this.bentoWidth, 0.65 * this.bentoHeight), this.bento6Material);
        this.bentoGeometryTall = new RoundedBoxGeometry(0.235 * this.bentoWidth, 0.65 * this.bentoHeight, 0.5, 3, 10)
        this.bento6 = new THREE.Mesh(this.bentoGeometryTall, this.bentoMaterial)

        this.bento7Material = new THREE.MeshStandardMaterial({ map: this.technical, opacity: 0.9, transparent: true, metalness: 0.75, roughness: 0.9 })
        this.bento7Front = new THREE.Mesh(new THREE.PlaneGeometry(0.49 * this.bentoWidth, 0.65 * this.bentoHeight), this.bento7Material);
        this.bentoGeometryBig = new RoundedBoxGeometry(0.49 * this.bentoWidth, 0.65 * this.bentoHeight, 0.5, 3, 10)
        this.bento7 = new THREE.Mesh(this.bentoGeometryBig, this.bentoMaterial)


        this.scene.add(this.bento1, this.bento1Front, this.bento2, this.bento2Front, this.bento3, this.bento3Front, this.bento4, this.bento4Front, this.bento5, this.bento5Front, this.bento6, this.bento6Front, this.bento7, this.bento7Front);
        this.bento1.layers.enable(3)
        this.bento2.layers.enable(3)
        this.bento3.layers.enable(3)
        this.bento4.layers.enable(3)
        this.bento5.layers.enable(3)
        this.bento6.layers.enable(3)
        this.bento7.layers.enable(3)
        this.bento1Front.layers.enable(3)
        this.bento2Front.layers.enable(3)
        this.bento3Front.layers.enable(3)
        this.bento4Front.layers.enable(3)
        this.bento5Front.layers.enable(3)
        this.bento6Front.layers.enable(3)
        this.bento7Front.layers.enable(3)



        this.bento1.castShadow = true
        this.bento1.receiveShadow = false
        this.bento2.castShadow = true
        this.bento2.receiveShadow = false
        this.bento3.castShadow = true
        this.bento3.receiveShadow = false
        this.bento4.castShadow = true
        this.bento4.receiveShadow = false
        this.bento5.castShadow = true
        this.bento5.receiveShadow = false
        this.bento6.castShadow = true
        this.bento6.receiveShadow = false
        this.bento7.castShadow = true
        this.bento7.receiveShadow = false

        this.bento1.scale.z = 0.2
        this.bento2.scale.z = 0.2
        this.bento3.scale.z = 0.2
        this.bento4.scale.z = 0.2
        this.bento5.scale.z = 0.2
        this.bento6.scale.z = 0.2
        this.bento7.scale.z = 0.2
        this.bento1Front.position.z = 0.12
        this.bento2Front.position.z = 0.12
        this.bento3Front.position.z = 0.12
        this.bento4Front.position.z = 0.12
        this.bento5Front.position.z = 0.12
        this.bento6Front.position.z = 0.12
        this.bento7Front.position.z = 0.12

        this.bento1Front.scale.set(0.98, 0.98, 0.98)
        this.bento2Front.scale.set(0.98, 0.98, 0.98)
        this.bento3Front.scale.set(0.98, 0.98, 0.98)
        this.bento4Front.scale.set(0.98, 0.98, 0.98)
        this.bento5Front.scale.set(0.98, 0.98, 0.98)
        this.bento6Front.scale.set(0.98, 0.98, 0.98)
        this.bento7Front.scale.set(0.98, 0.98, 0.98)

    }

    createLights1920() {
        this.ambientLight = new THREE.AmbientLight(0xffffff, 2.1);
        this.scene.add(this.ambientLight);

        this.pointLight = new THREE.PointLight(0x800080, 610, 30);
        // this.pointLight = new THREE.SpotLight(0x800080, 1, 30, Math.PI , 1, 0.1);
        // this.pointLight.target.position.set(-4, -35, -3);
        this.scene.add(this.pointLight);
        // this.pointLight.castShadow = true

        this.pointLight2 = new THREE.PointLight(0x000080, 4000, 30);
        this.scene.add(this.pointLight2);
        this.pointLight2.castShadow = true


    }

    positionBoxes1920() {
        let bounds = this.getViewBounds(this.experience.camera.instance, 13)

        let yPosition = (document.querySelector('#startpage').getBoundingClientRect().height * 0.5 + document.querySelector('#whitespace1').getBoundingClientRect().height + document.querySelector('#visionpage').getBoundingClientRect().height + document.querySelector('#projectpage').getBoundingClientRect().height) / this.sizes.height * -10 + 3
        document.querySelector('#usppage').style.height = Math.abs((-3 - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight - 0.65 * this.bentoHeight) / bounds.height) * 100 + "vh"
        this.backgroundBento.position.set(-4, yPosition - 20, -0.35)
        this.textMesh.position.set(-6.05, yPosition - 2.5, 0)
        this.backgroundBento.scale.z = 1
        this.bentoX = -4 - 0.5 * this.bentoWidth
        this.bentoY = yPosition - 3
        this.bentoZ = 0

        this.bento1.position.x = this.bentoX + 0.5 * 0.49 * this.bentoWidth;
        this.bento1.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;
        this.bento1Front.position.x = this.bentoX + 0.5 * 0.49 * this.bentoWidth;
        this.bento1Front.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;

        this.bento2.position.x = this.bentoX + 0.49 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth;
        this.bento2.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;
        this.bento2Front.position.x = this.bentoX + 0.49 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth;
        this.bento2Front.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;

        this.bento3.position.x = this.bentoX + 0.49 * this.bentoWidth + 0.02 * this.bentoWidth + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento3.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight
        this.bento3Front.position.x = this.bentoX + 0.49 * this.bentoWidth + 0.02 * this.bentoWidth + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento3Front.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;

        this.bento4.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento4.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight
        this.bento4Front.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento4Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight

        this.bento5.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento5.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.03 * this.bentoHeight - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight
        this.bento5Front.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento5Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight

        this.bento6.position.x = this.bentoX + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento6.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight
        this.bento6Front.position.x = this.bentoX + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento6Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight

        this.bento7.position.x = this.bentoX + 0.49 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.49 * this.bentoWidth
        this.bento7.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight
        this.bento7Front.position.x = this.bentoX + 0.49 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.49 * this.bentoWidth
        this.bento7Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight
    }

    positionLights1920() {
        this.pointLight.position.set(7, -33, 7);
        this.pointLight2.position.set(-15, -42, 8);
        this.pointLight.intensity = 30
        this.pointLight2.intensity = 40
    }

    updateBackground1920() {
        let fact
        let factLights = 0
        let startColor = new THREE.Color(0xEFEFF7)
        let endColor = new THREE.Color(0x010101)
        let endColorText = new THREE.Color(0x222222)
        let offset = 200
        if ((this.mouse.scrollPos + offset) > this.mouse.scrollPositions.projectpage.bottom) {
            fact = ((this.mouse.scrollPos + offset) - this.mouse.scrollPositions.projectpage.bottom) / ((this.mouse.scrollPositions.usppage.top + 200) - this.mouse.scrollPositions.projectpage.bottom)
        } else fact = 0
        if (fact > 1) fact = 1

        if ((this.mouse.scrollPos + offset) > this.mouse.scrollPositions.projectpage.bottom - 200) {
            factLights = 1 - ((this.mouse.scrollPos + offset) - this.mouse.scrollPositions.projectpage.bottom - 200) / ((this.mouse.scrollPositions.usppage.top) - this.mouse.scrollPositions.projectpage.bottom - 200)
        } else factLights = 0
        if (factLights > 1) factLights = 1
        const currentColor = startColor.clone().lerp(endColor, fact);
        const textColor = endColorText.clone().lerp(startColor, fact)
        document.querySelector('.link-title1').style.color = "#" + textColor.getHexString()
        document.querySelector('.link-title2').style.color = "#" + textColor.getHexString()
        document.querySelector('#activeItem').style.borderColor = "#" + textColor.getHexString()
        let links = document.querySelectorAll('.language__link p')
        // links[0].style.color = "#" + textColor.getHexString()
        // links[1].style.color = "#" + textColor.getHexString()
        let activeLink = document.querySelector('.active .language__link p')
        activeLink.style.color = "#" + textColor.getHexString()

        this.renderer.instance.setClearColor(currentColor)
        this.pointLight.intensity = Math.max(200, factLights * 610)
        this.pointLight2.intensity = Math.max(400, factLights * 4000)

        if (this.flashLight) this.flashLight.intensity = 0 + Math.pow(fact, 10) * 7
    }

    updateFlashlight1920() {
        if (this.scene.children.includes(this.scene.getObjectByName("flashLight"))) {
            let normedMouse = { x: this.mouse.normX, y: this.mouse.normY }
            this.raycaster.setFromCamera(normedMouse, this.camera.instance)
            const intersect = this.raycaster.intersectObject(this.backgroundBento)
            if (this.flashLight) {
                if (intersect.length > 0) {
                    this.flashLight.position.set(
                        intersect[0].point.x,
                        intersect[0].point.y,
                        7
                    )
                    this.flashLight.target.position.set(
                        intersect[0].point.x,
                        intersect[0].point.y,
                        0);
                } else {
                    this.flashLight.position.set(
                        -4,
                        3 - (this.mouse.scrollPos / this.sizes.height) * 14,
                        7
                    )
                    this.flashLight.target.position.set(-4 + this.mouse.normX * 10, 3 + 3 - (this.mouse.scrollPos / this.sizes.height) * 14, 0);
                }
            }
        } else {
            if (this.mouse.x != 0 && this.mouse.y != 0) {
                this.flashLight = new THREE.SpotLight(0xffffff, 7, 10, Math.PI / 4, 1, 0.1);
                this.flashLight.name = 'flashLight'
                this.flashLight.position.set(-4, 3, 7);
                this.flashLight.target.position.set(0, 0, 0);
                this.scene.add(this.flashLight, this.flashLight.target);
                this.flashLight.castShadow = true
            }
        }
    }

    remove1920Boxes() {
        this.scene.remove(this.textMesh)
        this.scene.remove(this.bento1, this.bento1Front, this.bento2, this.bento2Front, this.bento3, this.bento3Front, this.bento4, this.bento4Front, this.bento5, this.bento5Front, this.bento6, this.bento6Front, this.bento7, this.bento7Front)
    }

    // * 750

    positionBoxes750() {

        let yPosition = (document.querySelector('#startpage').getBoundingClientRect().height * 0.5 + document.querySelector('#whitespace1').getBoundingClientRect().height + document.querySelector('#visionpage').getBoundingClientRect().height + document.querySelector('#projectpage').getBoundingClientRect().height) / this.sizes.height * -10 + 3
        // console.log(yPosition)
        this.backgroundBento.position.set(-4, yPosition - 20, -0.35)
        this.backgroundBento.scale.z = 1
        this.textMesh.position.set(-6.05, yPosition - 1.5, 0);

        this.bentoX = 0.65 - 0.5 * this.bentoWidth
        this.bentoY = yPosition - 2
        this.bentoZ = 0

        let bounds = this.getViewBounds(this.camera.instance, (1 - this.camera.instance.position.z))
        let testHeight = - 0.31 * this.bentoHeight - this.bentoHeight * 1.05 - this.bentoHeight * 0.31 - 0.04 * this.bentoHeight - this.bentoHeight * 0.31
        document.querySelector('#usppage').style.height = Math.abs(testHeight / bounds.height) * 110 + "vh"
        // this.backgroundBento.position.set(-4, -46, -0.35)


        // this.textMesh.position.set(-6.05, -28.5, 0);
        // this.bentoX = 0.65 - 0.5 * this.bentoWidth
        // this.bentoY = -29
        // this.bentoZ = 0

        this.bento1.position.x = this.bentoX + 0.5 * 0.49 * this.bentoWidth;
        this.bento1.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;
        this.bento1Front.position.x = this.bentoX + 0.5 * 0.49 * this.bentoWidth;
        this.bento1Front.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight;

        this.bento2.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento2.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight - this.bentoHeight * 1.05;
        this.bento2Front.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento2Front.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight - this.bentoHeight * 1.05;

        this.bento3.position.x = this.bentoX + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento3.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight - this.bentoHeight * 1.05
        this.bento3Front.position.x = this.bentoX + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento3Front.position.y = this.bentoY - 0.5 * 0.31 * this.bentoHeight - this.bentoHeight * 1.05

        this.bento4.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento4.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight
        this.bento4Front.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento4Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight

        this.bento5.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento5.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight
        this.bento5Front.position.x = this.bentoX + 0.5 * 0.235 * this.bentoWidth
        this.bento5Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.31 * this.bentoHeight

        this.bento6.position.x = this.bentoX + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento6.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight
        this.bento6Front.position.x = this.bentoX + 0.235 * this.bentoWidth + 0.02 * this.bentoWidth + 0.5 * 0.235 * this.bentoWidth
        this.bento6Front.position.y = this.bentoY - 0.31 * this.bentoHeight - 0.04 * this.bentoHeight - 0.5 * 0.65 * this.bentoHeight

        this.bento7.position.x = this.bentoX + 0.5 * 0.49 * this.bentoWidth
        this.bento7.position.y = this.bentoY - 0.31 * this.bentoHeight - this.bentoHeight * 1.05 - this.bentoHeight * 0.31 - 0.04 * this.bentoHeight
        this.bento7Front.position.x = this.bentoX + 0.5 * 0.49 * this.bentoWidth
        this.bento7Front.position.y = this.bentoY - 0.31 * this.bentoHeight - this.bentoHeight * 1.05 - this.bentoHeight * 0.31 - 0.04 * this.bentoHeight
    }

    positionLights750() {
        this.pointLight.position.set(10, -35, 7);
        this.pointLight2.position.set(-12, -45, 8);
    }

    // * 280

    createBoxes280() {


        const geometry = new TextGeometry('Services', {
            font: this.resources.items.serviceFont,
            size: 1,
            height: 0.1,
            curveSegments: 12,
            bevelEnabled: false,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelSegments: 5
        });
        const material = new THREE.MeshStandardMaterial({ color: 0xdddddd, metalness: 0.3, roughness: 0.46 }); // Use the THREE.MeshStandardMaterial class
        // const material = new THREE.MeshBasicMaterial({ color: 0xffffff}); // Use the THREE.MeshStandardMaterial class
        this.textMesh = new THREE.Mesh(geometry, material);
        this.scene.add(this.textMesh)
        this.textMesh.scale.set(1, 1, 0.05)

        this.bentoMaterial = new THREE.MeshPhysicalMaterial({ color: 0x111111, metalness: 0.6, roughness: 0.5 });
        this.bentoWidth = 4.6
        this.bentoHeight = 8
        this.bentoGeometry = new RoundedBoxGeometry(this.bentoWidth, this.bentoHeight, 0.5, 3, 2);

        this.bento1Material = new THREE.MeshStandardMaterial({ map: this.tailored3D_mobile, transparent: true })
        this.bento1Front = new THREE.Mesh(new THREE.PlaneGeometry(this.bentoWidth, this.bentoHeight), this.bento1Material);
        this.bento1 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial);
        this.bento1Group = new THREE.Group()
        this.bento1Group.add(this.bento1, this.bento1Front)

        this.bento2Material = new THREE.MeshStandardMaterial({ map: this.optimized_mobile, transparent: true })
        this.bento2Front = new THREE.Mesh(new THREE.PlaneGeometry(this.bentoWidth, this.bentoHeight), this.bento2Material);
        this.bento2 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial);
        this.bento2Group = new THREE.Group()
        this.bento2Group.add(this.bento2, this.bento2Front)

        this.bento3Material = new THREE.MeshStandardMaterial({ map: this.expertise_mobile, transparent: true })
        this.bento3Front = new THREE.Mesh(new THREE.PlaneGeometry(this.bentoWidth, this.bentoHeight), this.bento3Material);
        this.bento3 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)
        this.bento3Group = new THREE.Group()
        this.bento3Group.add(this.bento3, this.bento3Front)

        this.bento4Material = new THREE.MeshStandardMaterial({ map: this.transparent_mobile, transparent: true })
        this.bento4Front = new THREE.Mesh(new THREE.PlaneGeometry(this.bentoWidth, this.bentoHeight), this.bento4Material);
        this.bento4 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)
        this.bento4Group = new THREE.Group()
        this.bento4Group.add(this.bento4, this.bento4Front)

        this.bento5Material = new THREE.MeshStandardMaterial({ map: this.responsive_mobile, transparent: true })
        this.bento5Front = new THREE.Mesh(new THREE.PlaneGeometry(this.bentoWidth, this.bentoHeight), this.bento5Material);
        this.bento5 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)
        this.bento5Group = new THREE.Group()
        this.bento5Group.add(this.bento5, this.bento5Front)

        this.bento6Material = new THREE.MeshStandardMaterial({ map: this.insight_mobile, transparent: true })
        this.bento6Front = new THREE.Mesh(new THREE.PlaneGeometry(this.bentoWidth, this.bentoHeight), this.bento6Material);
        this.bento6 = new THREE.Mesh(this.bentoGeometry, this.bentoMaterial)
        this.bento6Group = new THREE.Group()
        this.bento6Group.add(this.bento6, this.bento6Front)


        this.bento1Front.position.z = 0.6
        this.bento2Front.position.z = 0.6
        this.bento3Front.position.z = 0.6
        this.bento4Front.position.z = 0.6
        this.bento5Front.position.z = 0.6
        this.bento6Front.position.z = 0.6
        this.scene.add(this.bento1Group, this.bento2Group, this.bento3Group, this.bento4Group, this.bento5Group, this.bento6Group)

        let scaleFactor = (this.sizes.width / this.sizes.height + 0.1) * 0.9
        this.bento1Group.scale.set(scaleFactor, scaleFactor, 0.2)
        this.bento2Group.scale.set(scaleFactor, scaleFactor, 0.2)
        this.bento3Group.scale.set(scaleFactor, scaleFactor, 0.2)
        this.bento4Group.scale.set(scaleFactor, scaleFactor, 0.2)
        this.bento5Group.scale.set(scaleFactor, scaleFactor, 0.2)
        this.bento6Group.scale.set(scaleFactor, scaleFactor, 0.2)
        this.bento1Front.scale.set(0.97, 0.97, 0.97)
        this.bento2Front.scale.set(0.97, 0.97, 0.97)
        this.bento3Front.scale.set(0.97, 0.97, 0.97)
        this.bento4Front.scale.set(0.97, 0.97, 0.97)
        this.bento5Front.scale.set(0.97, 0.97, 0.97)
        this.bento6Front.scale.set(0.97, 0.97, 0.97)




        this.bentoCards = {
            bento1: this.bento1Group,
            bento2: this.bento2Group,
            bento3: this.bento3Group,
            bento4: this.bento4Group,
            bento5: this.bento5Group,
            bento6: this.bento6Group
        }
    }

    getPositions(pos, offset, zOffset = 0) {
        let x = Math.sin((offset + pos) / 6 * 2 * Math.PI) * 5.2 * (this.sizes.width / this.sizes.height + 0.1) - 4
        let z = -Math.cos((offset + pos) / 6 * 2 * Math.PI) * 1.5 * (this.sizes.width / this.sizes.height) - 0.1 + zOffset

        let rotation = 0

        let scale = -Math.cos((offset + pos) / 6 * 2 * Math.PI) * 0.2 + (this.sizes.width / this.sizes.height + 0.1) * 0.9 - 0.2
        return { x, z, rotation, scale }
    }

    getViewBounds(camera, distance) {
        const vFov = camera.fov * Math.PI / 180; // Convert vertical fov to radians
        // console.log(vFov, camera.aspect, distance)
        const height = 2 * distance * Math.tan(vFov / 2); // Height of the visible area at the distance
        const width = height * camera.aspect; // Width of the visible area at the distance
        // console.log('width: ', Math.abs(width), 'height: ', Math.abs(height))
        return {
            width: Math.abs(width),
            height: Math.abs(height)
        };
    }

    positionBoxes280() {

        let bounds = this.getViewBounds(this.experience.camera.instance, 10.4)
        this.yPosition = (document.querySelector('#startpage').getBoundingClientRect().height * 0.5 + document.querySelector('#whitespace1').getBoundingClientRect().height + document.querySelector('#visionpage').getBoundingClientRect().height + document.querySelector('#projectpage').getBoundingClientRect().height) / this.sizes.height * -10 + 3
        this.backgroundBento.position.set(-4, this.yPosition - 20, -0.1)
        this.backgroundBento.scale.z = 10


        this.textMesh.scale.x = bounds.width / 8
        this.textMesh.scale.y = bounds.width / 8
        var box = new THREE.Box3().setFromObject(this.textMesh);
        var size = new THREE.Vector3();
        box.getSize(size);
        this.textMesh.position.set(-4 - size.x / 2, this.yPosition - bounds.width / 4, 2.5);
        let zOffset = 2.6

        document.querySelector('#usppage').style.height = Math.abs((0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9) + 0.7 * (this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9))) / bounds.height) * 95 + "vh"
        this.activeBox = 1
        this.offset = 0

        this.bento1Group.position.set(this.getPositions(0, 0, zOffset).x, this.yPosition - 0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9), this.getPositions(0, 0, zOffset).z)
        this.bento1Group.scale.set(this.getPositions(0, 0, zOffset).scale, this.getPositions(0, 0, zOffset).scale, 0.2)

        this.bento2Group.position.set(this.getPositions(1, 0, zOffset).x, this.yPosition - 0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9), this.getPositions(1, 0, zOffset).z)
        this.bento2Group.scale.set(this.getPositions(1, 0, zOffset).scale, this.getPositions(1, 0, zOffset).scale, 0.2)

        this.bento3Group.position.set(this.getPositions(2, 0, zOffset).x, this.yPosition - 0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9), this.getPositions(2, 0, zOffset).z)
        this.bento3Group.scale.set(this.getPositions(2, 0, zOffset).scale, this.getPositions(2, 0, zOffset).scale, 0.2)

        this.bento4Group.position.set(this.getPositions(3, 0, zOffset).x, this.yPosition - 0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9), this.getPositions(3, 0, zOffset).z)
        this.bento4Group.scale.set(this.getPositions(3, 0, zOffset).scale, this.getPositions(3, 0, zOffset).scale, 0.2)

        this.bento5Group.position.set(this.getPositions(4, 0, zOffset).x, this.yPosition - 0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9), this.getPositions(4, 0, zOffset).z)
        this.bento5Group.scale.set(this.getPositions(4, 0, zOffset).scale, this.getPositions(4, 0, zOffset).scale, 0.2)

        this.bento6Group.position.set(this.getPositions(5, 0, zOffset).x, this.yPosition - 0.9 * this.bentoHeight * ((this.sizes.width / this.sizes.height + 0.1) * 0.9), this.getPositions(5, 0, zOffset).z)
        this.bento6Group.scale.set(this.getPositions(5, 0, zOffset).scale, this.getPositions(5, 0, zOffset).scale, 0.2)



        this.boxPositions280 = [-4, 1, 6, -19, -14, -9]

    }

    positionLights280() {
        // this.pointLight.intensity = 610
        // this.pointLight2.intensity = 4000
        this.pointLight.position.set(1, this.yPosition - 6, 13);
        this.pointLight2.position.set(-15, this.yPosition - 8, 15);
    }

    updateFlashlight280() {

    }

    slideCard(event) {
        this.touchEnded = false
        const touch = event;
        if (Math.abs(touch.clientX - this.startX) > Math.abs(touch.clientY - this.startY)) {
            this.velTouchX = ((event.clientX) / this.sizes.width) * 2 - 1 - this.touchX
            this.touchX = ((event.clientX) / this.sizes.width) * 2 - 1
            this.swipeOffset = this.offset + (this.touchStart.x - this.touchX)
            let zOffset = 2.6
            for (let i = 1; i < 7; i++) {
                this.bentoCards[`bento${i}`].position.x = this.getPositions(i - 1, this.swipeOffset, zOffset).x
                this.bentoCards[`bento${i}`].position.z = this.getPositions(i - 1, this.swipeOffset, zOffset).z
                this.bentoCards[`bento${i}`].scale.x = this.getPositions(i - 1, this.swipeOffset, zOffset).scale
                this.bentoCards[`bento${i}`].scale.y = this.getPositions(i - 1, this.swipeOffset, zOffset).scale
            }
        }
    }

    getArrayPosition(selection, active, offset) {
        let pos = (selection + offset) - active < 0 ? (selection + offset) - active + 6 : ((selection + offset) - active) % 6
        return pos
    }

    slideEnd() {
        this.offset += (this.touchStart.x - this.touchX)
    }

    remove280Boxes() {
        this.scene.remove(this.bento1Group, this.bento2Group, this.bento3Group, this.bento4Group, this.bento5Group, this.bento6Group)
    }

    moveLeft() {
        this.offset -= 1
        this.newOffset = Math.round(this.offset)
        this.offset = this.newOffset
        // console.log('offset end' + this.offset)
        this.inGsap = true
        let zOffset = 2.6

        if (this.bento1Group) gsap.to(this.bento1Group.position, { x: this.getPositions(0, this.newOffset, zOffset).x, z: this.getPositions(0, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento1Group) gsap.to(this.bento1Group.scale, { x: this.getPositions(0, this.newOffset, zOffset).scale, y: this.getPositions(0, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento2Group) gsap.to(this.bento2Group.position, { x: this.getPositions(1, this.newOffset, zOffset).x, z: this.getPositions(1, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento2Group) gsap.to(this.bento2Group.scale, { x: this.getPositions(1, this.newOffset, zOffset).scale, y: this.getPositions(1, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento3Group) gsap.to(this.bento3Group.position, { x: this.getPositions(2, this.newOffset, zOffset).x, z: this.getPositions(2, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento3Group) gsap.to(this.bento3Group.scale, { x: this.getPositions(2, this.newOffset, zOffset).scale, y: this.getPositions(2, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento4Group) gsap.to(this.bento4Group.position, { x: this.getPositions(3, this.newOffset, zOffset).x, z: this.getPositions(3, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento4Group) gsap.to(this.bento4Group.scale, { x: this.getPositions(3, this.newOffset, zOffset).scale, y: this.getPositions(3, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento5Group) gsap.to(this.bento5Group.position, { x: this.getPositions(4, this.newOffset, zOffset).x, z: this.getPositions(4, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento5Group) gsap.to(this.bento5Group.scale, { x: this.getPositions(4, this.newOffset, zOffset).scale, y: this.getPositions(4, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento6Group) gsap.to(this.bento6Group.position, { x: this.getPositions(5, this.newOffset, zOffset).x, z: this.getPositions(5, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento6Group) gsap.to(this.bento6Group.scale, { x: this.getPositions(5, this.newOffset, zOffset).scale, y: this.getPositions(5, this.newOffset, zOffset).scale, duration: 0.3 })

        setTimeout(() => {
            this.inGsap = false
        }, 300)
    }

    moveRight() {
        this.offset += 1
        this.newOffset = Math.round(this.offset)
        this.offset = this.newOffset
        // console.log('offset end' + this.offset)
        this.inGsap = true
        let zOffset = 2.6

        if (this.bento1Group) gsap.to(this.bento1Group.position, { x: this.getPositions(0, this.newOffset, zOffset).x, z: this.getPositions(0, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento1Group) gsap.to(this.bento1Group.scale, { x: this.getPositions(0, this.newOffset, zOffset).scale, y: this.getPositions(0, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento2Group) gsap.to(this.bento2Group.position, { x: this.getPositions(1, this.newOffset, zOffset).x, z: this.getPositions(1, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento2Group) gsap.to(this.bento2Group.scale, { x: this.getPositions(1, this.newOffset, zOffset).scale, y: this.getPositions(1, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento3Group) gsap.to(this.bento3Group.position, { x: this.getPositions(2, this.newOffset, zOffset).x, z: this.getPositions(2, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento3Group) gsap.to(this.bento3Group.scale, { x: this.getPositions(2, this.newOffset, zOffset).scale, y: this.getPositions(2, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento4Group) gsap.to(this.bento4Group.position, { x: this.getPositions(3, this.newOffset, zOffset).x, z: this.getPositions(3, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento4Group) gsap.to(this.bento4Group.scale, { x: this.getPositions(3, this.newOffset, zOffset).scale, y: this.getPositions(3, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento5Group) gsap.to(this.bento5Group.position, { x: this.getPositions(4, this.newOffset, zOffset).x, z: this.getPositions(4, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento5Group) gsap.to(this.bento5Group.scale, { x: this.getPositions(4, this.newOffset, zOffset).scale, y: this.getPositions(4, this.newOffset, zOffset).scale, duration: 0.3 })
        if (this.bento6Group) gsap.to(this.bento6Group.position, { x: this.getPositions(5, this.newOffset, zOffset).x, z: this.getPositions(5, this.newOffset, zOffset).z, duration: 0.3 })
        if (this.bento6Group) gsap.to(this.bento6Group.scale, { x: this.getPositions(5, this.newOffset, zOffset).scale, y: this.getPositions(5, this.newOffset, zOffset).scale, duration: 0.3 })

        setTimeout(() => {
            this.inGsap = false
        }, 300)
    }


    update() {
        this.updateBackground1920()
        if (this.sizes.width <= 500) this.updateFlashlight280()
        else if (this.sizes.width >= 750) this.updateFlashlight1920()

        else if (this.flashLight) this.flashLight.intensity = 0
        if (this.activeEllipse != Math.round(this.offset) < 0 ? 6 + Math.round(this.offset) % 6 : Math.round(this.offset) % 6) {
            this.activeEllipse = Math.round(this.offset) < 0 ? 6 + Math.round(this.offset) % 6 : Math.round(this.offset) % 6
            // console.log(this.offset < 0 ? 6 + this.offset % 6 : this.offset % 6)
            document.querySelector('#ellipse1').classList.remove('active')
            document.querySelector('#ellipse2').classList.remove('active')
            document.querySelector('#ellipse3').classList.remove('active')
            document.querySelector('#ellipse4').classList.remove('active')
            document.querySelector('#ellipse5').classList.remove('active')
            document.querySelector('#ellipse6').classList.remove('active')

            switch (this.activeEllipse) {
                case 0:
                    document.querySelector('#ellipse1').classList.add('active')
                    break
                case 1:
                    document.querySelector('#ellipse2').classList.add('active')
                    break
                case 2:
                    document.querySelector('#ellipse3').classList.add('active')
                    break
                case 3:
                    document.querySelector('#ellipse4').classList.add('active')
                    break
                case 4:
                    document.querySelector('#ellipse5').classList.add('active')
                    break
                case 5:
                    document.querySelector('#ellipse6').classList.add('active')
                    break
                case 6:
                    document.querySelector('#ellipse1').classList.add('active')
                    break
            }
        }




        if (!this.isSliding) {
            if (Math.abs(this.velTouchX) > 0.008) {
                this.offset -= this.velTouchX

                this.velTouchX > 0 ? this.velTouchX -= 0.008 : this.velTouchX += 0.008
                let zOffset = 2.6

                if (this.bento1Group) {
                    this.bento1Group.position.x = this.getPositions(0, this.offset, zOffset).x
                    this.bento1Group.position.z = this.getPositions(0, this.offset, zOffset).z
                    this.bento1Group.scale.x = this.getPositions(0, this.offset, zOffset).scale
                    this.bento1Group.scale.y = this.getPositions(0, this.offset, zOffset).scale
                }
                if (this.bento2Group) {
                    this.bento2Group.position.x = this.getPositions(1, this.offset, zOffset).x
                    this.bento2Group.position.z = this.getPositions(1, this.offset, zOffset).z
                    this.bento2Group.scale.x = this.getPositions(1, this.offset, zOffset).scale
                    this.bento2Group.scale.y = this.getPositions(1, this.offset, zOffset).scale
                }
                if (this.bento3Group) {
                    this.bento3Group.position.x = this.getPositions(2, this.offset, zOffset).x
                    this.bento3Group.position.z = this.getPositions(2, this.offset, zOffset).z
                    this.bento3Group.scale.x = this.getPositions(2, this.offset, zOffset).scale
                    this.bento3Group.scale.y = this.getPositions(2, this.offset, zOffset).scale
                }
                if (this.bento4Group) {
                    this.bento4Group.position.x = this.getPositions(3, this.offset, zOffset).x
                    this.bento4Group.position.z = this.getPositions(3, this.offset, zOffset).z
                    this.bento4Group.scale.x = this.getPositions(3, this.offset, zOffset).scale
                    this.bento4Group.scale.y = this.getPositions(3, this.offset, zOffset).scale
                }
                if (this.bento5Group) {
                    this.bento5Group.position.x = this.getPositions(4, this.offset, zOffset).x
                    this.bento5Group.position.z = this.getPositions(4, this.offset, zOffset).z
                    this.bento5Group.scale.x = this.getPositions(4, this.offset, zOffset).scale
                    this.bento5Group.scale.y = this.getPositions(4, this.offset, zOffset).scale
                }
                if (this.bento6Group) {
                    this.bento6Group.position.x = this.getPositions(5, this.offset, zOffset).x
                    this.bento6Group.position.z = this.getPositions(5, this.offset, zOffset).z
                    this.bento6Group.scale.x = this.getPositions(5, this.offset, zOffset).scale
                    this.bento6Group.scale.y = this.getPositions(5, this.offset, zOffset).scale
                }
            } else {
                if (!this.touchEnded) {
                    this.touchEnded = true
                    // console.log('offset before' + this.offset)
                    this.newOffset = Math.round(this.offset)
                    this.offset = this.newOffset
                    // console.log('offset end' + this.offset)
                    this.inGsap = true
                    let zOffset = 2.6

                    if (this.bento1Group) gsap.to(this.bento1Group.position, { x: this.getPositions(0, this.newOffset, zOffset).x, z: this.getPositions(0, this.newOffset, zOffset).z, duration: 0.3 })
                    if (this.bento1Group) gsap.to(this.bento1Group.scale, { x: this.getPositions(0, this.newOffset, zOffset).scale, y: this.getPositions(0, this.newOffset, zOffset).scale, duration: 0.3 })
                    if (this.bento2Group) gsap.to(this.bento2Group.position, { x: this.getPositions(1, this.newOffset, zOffset).x, z: this.getPositions(1, this.newOffset, zOffset).z, duration: 0.3 })
                    if (this.bento2Group) gsap.to(this.bento2Group.scale, { x: this.getPositions(1, this.newOffset, zOffset).scale, y: this.getPositions(1, this.newOffset, zOffset).scale, duration: 0.3 })
                    if (this.bento3Group) gsap.to(this.bento3Group.position, { x: this.getPositions(2, this.newOffset, zOffset).x, z: this.getPositions(2, this.newOffset, zOffset).z, duration: 0.3 })
                    if (this.bento3Group) gsap.to(this.bento3Group.scale, { x: this.getPositions(2, this.newOffset, zOffset).scale, y: this.getPositions(2, this.newOffset, zOffset).scale, duration: 0.3 })
                    if (this.bento4Group) gsap.to(this.bento4Group.position, { x: this.getPositions(3, this.newOffset, zOffset).x, z: this.getPositions(3, this.newOffset, zOffset).z, duration: 0.3 })
                    if (this.bento4Group) gsap.to(this.bento4Group.scale, { x: this.getPositions(3, this.newOffset, zOffset).scale, y: this.getPositions(3, this.newOffset, zOffset).scale, duration: 0.3 })
                    if (this.bento5Group) gsap.to(this.bento5Group.position, { x: this.getPositions(4, this.newOffset, zOffset).x, z: this.getPositions(4, this.newOffset, zOffset).z, duration: 0.3 })
                    if (this.bento5Group) gsap.to(this.bento5Group.scale, { x: this.getPositions(4, this.newOffset, zOffset).scale, y: this.getPositions(4, this.newOffset, zOffset).scale, duration: 0.3 })
                    if (this.bento6Group) gsap.to(this.bento6Group.position, { x: this.getPositions(5, this.newOffset, zOffset).x, z: this.getPositions(5, this.newOffset, zOffset).z, duration: 0.3 })
                    if (this.bento6Group) gsap.to(this.bento6Group.scale, { x: this.getPositions(5, this.newOffset, zOffset).scale, y: this.getPositions(5, this.newOffset, zOffset).scale, duration: 0.3 })

                    setTimeout(() => {
                        this.inGsap = false
                    }, 300)
                }
            }

        }
    }
}