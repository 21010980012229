import * as THREE from 'three';
import planck from 'planck/dist/planck';

export default class FluidBalls {
    constructor(experience) {
        this.experience = experience
        this.scene = experience.scene
        this.resources = experience.resources
        this.time = experience.time
        this.sizes = experience.sizes
        this.debug = experience.debug.ui
        this.mouse = experience.mouse
        this.camera = experience.camera

        this.diggaX = 0
        this.diggaY = 0
        this.worldX = 0
        this.worldY = 0

        this.threeBalls = []
        this.cannonBalls = []
        this.onReset = false
        this.deviation = 0
        this.towerVariant = 1
        this.inResize = false

        this.updateCount = 0
        this.updateCountStep = 0
        this.updateAfterTime = false

        this.pl = undefined
        this.world = undefined

        this.scrollActivated = false
        this.touchStarted = false

        this.mouseBallActive = false
        this.worldX = -20
        this.worldY = 20
        this.resizeTimer = undefined

        this.init()
    }

    init() {
        if (document.visibilityState === 'visible') {
            this.createCannonWorld()

            setTimeout(() => {
                this.getBorders()
            }, 100)

            document.querySelector('.scrollable-content').addEventListener('touchstart', (event) => {
                this.mouseBallActive = false
                this.worldX = -20
                this.worldY = 20
                if (!this.touchStarted) {
                    this.touchStarted = true
                    this.onTouchStart(event), { passive: false };
                }
            })

            document.querySelector('.scrollable-content').addEventListener('touchmove', (event) => {
                this.mouseBallActive = false
            })

            document.querySelector('.scrollable-content').addEventListener('touchend', (event) => {
                this.mouseBallActive = false
                this.worldX = -20
                this.worldY = 20
                setTimeout(() => {
                    this.touchStarted = false
                }, 100)
            })

            this.mouse.on('scroll', () => {
                if (!this.touchStarted && this.mouseBallActive) {
                    this.onMouseMove(event), { passive: false };
                }
            })

            document.querySelector('.scrollable-content').addEventListener('mousemove', (event) => {
                if (!this.touchStarted) {
                    this.mouseBallActive = true
                    this.onMouseMove(event), { passive: false };
                }
            })

            this.ballMoveElements = document.querySelectorAll('.animate-on-ballmove')

            this.sizes.on('resize', this.debounce(this.resize.bind(this), 100))

            document.querySelector('#resetButton').addEventListener('click', () => {
                this.updateAfterTime = false
                this.moveBackToStart()
                this.secondsLeft = 10
                document.querySelector('#countdown').innerHTML = '10'
                document.querySelector('#countdown').classList.remove('active')
            })
        } else {
            setTimeout(() => {
                this.init()
            }, 100)
        }
    }

    debounce(func, delay) {
        let timer;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, arguments);
            }, delay);
        };
    }

    resize() {
        console.log('resize')
        if (this.inResize) return
        this.inResize = true

        this.threeBalls.forEach(ball => {
            this.scene.remove(ball.mesh)
            this.scene.remove(ball.texture)
            this.world.destroyBody(ball.body)
        })
        this.threeBalls = []
        this.scene.remove(this.lighting)
        this.scene.remove(this.helpCamera, this.helperPlane)
        this.world.destroyBody(this.tankBody)
        this.threeBalls.forEach(ball => {
            this.scene.remove(ball.mesh)
            this.scene.remove(ball.texture)
            this.world.destroyBody(ball.body)
        })
        this.threeBalls = []
        this.scene.remove(this.lighting)
        this.scene.remove(this.helpCamera, this.helperPlane)
        this.world.destroyBody(this.tankBody)
        this.createCannonWorld()
        this.getBorders()
        setTimeout(() => {
            this.inResize = false
        }, 250)
    }

    pushAllUp(force) {
        this.threeBalls.forEach(ball => {
            ball.body.applyLinearImpulse(this.Vec2(0, force), new this.Vec2(ball.body.c_position.c.x, ball.body.c_position.c.y))
        })
    }

    getViewBounds(camera, distance) {
        const vFov = camera.fov * Math.PI / 180;
        const height = 2 * distance * Math.tan(vFov / 2);
        const width = height * camera.aspect;

        return {
            width: Math.abs(width),
            height: Math.abs(height)
        };
    }

    determineLeftAndRight() {
        let bounds = this.getViewBounds(this.camera.instance, 13.1)
        this.leftBorder = this.camera.instance.position.x - bounds.width / 2
        this.rightBorder = this.camera.instance.position.x + bounds.width / 2
        this.determinedHeight = bounds.height

        this.createFloor()
        if (this.sizes.width > 1200) this.createMouseBall()
    }

    getBorders() {
        this.backgroundBento = this.experience.home.bentos.backgroundBento
        this.blackBorderY = this.backgroundBento.position.y + Math.abs(this.backgroundBento.geometry.attributes.position.array[1])

        this.helperPlane = new THREE.Mesh(new THREE.PlaneGeometry(100, 100, 1, 1), new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0, depthWrite: false }))
        this.helperPlane.position.x = this.backgroundBento.position.x
        this.helperPlane.position.y = 0
        this.helperPlane.position.z = -0.1
        this.scene.add(this.helperPlane)

        setTimeout(() => {
            this.determineLeftAndRight()
        }, 1000)

    }

    createCannonWorld() {
        this.pl = planck
        this.Vec2 = this.pl.Vec2;
        this.world = new this.pl.World({
            gravity: this.Vec2(0, -20)
        });
    }

    // ! Look here 
    createBox(width, height, x, y, rotation, color, text, secondX, secondY, secondRot, thirdX, thirdY, thirdRot) {
        let bounds = this.getViewBounds(this.camera.instance, 13.1)

        let xMovement = 0
        if (bounds.width / bounds.height <= 1.6) xMovement = Math.max(- (1.6 - bounds.width / bounds.height) * 20, -4)
        if (bounds.width <= 8) xMovement = -4

        let xyScale = 1
        if (bounds.width <= 16) xyScale = Math.min(bounds.width / 13, 1)
        let ballBody = this.world.createBody({
            type: 'dynamic',
            position: this.Vec2(x * xyScale + xMovement, this.blackBorderY + y * xyScale),
            allowSleep: false
        });
        ballBody.createFixture(this.pl.Box(width / 2, height / 2), {
            density: 1000,
            friction: 0.5,
            restitution: 0.003
        });

        // console.log(ballBody)

        const geometry = new THREE.BoxGeometry(width, height, 0.4);

        const material = new THREE.MeshPhysicalMaterial({ color, metalness: 0.5, roughness: 0.3, transparent: true, opacity: 1 });

        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(ballBody.getPosition().x, ballBody.getPosition().y, -0.3);
        mesh.rotation.z = rotation
        ballBody.setAngle(rotation)
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        this.scene.add(mesh);
        this.threeBalls.push({ mesh: mesh, body: ballBody, texture: text, start: { x: x * xyScale + xMovement, y: this.blackBorderY + y * xyScale, rotation: rotation }, second: { x: secondX * xyScale + xMovement, y: this.blackBorderY + secondY * xyScale, rotation: secondRot }, third: { x: thirdX * xyScale + xMovement, y: this.blackBorderY + thirdY * xyScale, rotation: thirdRot } });
    }

    createBall(radius, x, y, color) {
        let bounds = this.getViewBounds(this.camera.instance, 13.1)
        let xMovement = 0
        xMovement = Math.max(- (1.6 - bounds.width / bounds.height) * 20, -4)
        if (bounds.width <= 8) xMovement = -4
        let ballBody = this.world.createBody({
            type: 'dynamic',
            position: this.Vec2(x + xMovement, this.blackBorderY + y),
            allowSleep: false
        });
        ballBody.createFixture(this.pl.Circle(radius * 0.9), {
            density: 1000,
            friction: 1,
            restitution: 0.00001
        });

        const geometry = new THREE.IcosahedronGeometry(radius, 0);

        const material = new THREE.MeshToonMaterial({ color });
        this.resources.items.gradientTexture5.minFilter = THREE.NearestFilter
        this.resources.items.gradientTexture5.magFilter = THREE.NearestFilter
        this.resources.items.gradientTexture5.generateMipmaps = false
        material.gradientMap = this.resources.items.gradientTexture5

        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(ballBody.getPosition().x, ballBody.getPosition().y, -0.3);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        this.scene.add(mesh);
        this.threeBalls.push({ mesh: mesh, body: ballBody, start: { x: x, y: this.blackBorderY + y } });
    }

    scrollActivation() {
        if (this.scrollActivated) return
        this.scrollActivated = true
        if (this.sizes.width >= 750) {
            this.createBall(0.5 * this.scaleBall, -6, this.tankHeight - 1, new THREE.Color('#A1A1A1'))
        } else if (this.sizes.width >= 500) {
            this.createBall(0.5 * this.scaleBall, -6, this.tankHeight - 1, new THREE.Color('#A1A1A1'))
        } else if (this.sizes.width >= 280) {
            this.createBall(0.5 * this.scaleBall, -6, this.tankHeight - 1, new THREE.Color('#A1A1A1'))
        } else {
            this.createBall(0.5 * this.scaleBall, -6, this.tankHeight - 1, new THREE.Color('#A1A1A1'))
        }
        setTimeout(() => {
            this.ballMoveElements.forEach(element => {
                element.classList.add('animate')
            })
        }, 50)
    }

    createFloor() {
        this.tankBody = this.world.createBody();
        this.tankWidth = (this.rightBorder - this.leftBorder) + 0.5;
        this.tankHeight = this.determinedHeight + 0.5;

        this.tankBody.createFixture(this.pl.Box(this.tankWidth / 2, 0.2, this.Vec2(-4, this.blackBorderY - 0.2)), { density: 1 });
        this.tankBody.createFixture(this.pl.Box(this.tankWidth / 2, 0.2, this.Vec2(-4, this.blackBorderY - 0.2 + this.tankHeight)), { density: 1 });
        this.tankBody.createFixture(this.pl.Box(0.2, this.tankHeight / 2, this.Vec2(-4 - this.tankWidth / 2, this.blackBorderY + this.tankHeight / 2)), { density: 1 });
        this.tankBody.createFixture(this.pl.Box(0.2, this.tankHeight / 2, this.Vec2(-4 + this.tankWidth / 2, this.blackBorderY + this.tankHeight / 2)), { density: 1 });

        this.ballRadius = 0.2;
        let colorIndex = 0;

        const colors = ["343030", "3D4CD4", "22B3D3"];

        const showcase = new THREE.Mesh(new THREE.PlaneGeometry(4, 1.2), new THREE.MeshStandardMaterial({ map: this.resources.items.showcase, transparent: true, opacity: 1, depthWrite: false }))
        const custom = new THREE.Mesh(new THREE.PlaneGeometry(7, 1.2), new THREE.MeshStandardMaterial({ map: this.resources.items.custom, transparent: true, opacity: 1, depthWrite: false }))
        const portfolio = new THREE.Mesh(new THREE.PlaneGeometry(4, 1.0), new THREE.MeshStandardMaterial({ map: this.resources.items.portfolio, transparent: true, opacity: 1, depthWrite: false }))
        const virtual = new THREE.Mesh(new THREE.PlaneGeometry(4, 1.0), new THREE.MeshStandardMaterial({ map: this.resources.items.virtual, transparent: true, opacity: 1, depthWrite: false }))
        const simulation = new THREE.Mesh(new THREE.PlaneGeometry(3, 0.6), new THREE.MeshStandardMaterial({ map: this.resources.items.simulation, transparent: true, opacity: 1, depthWrite: false }))
        const configurator = new THREE.Mesh(new THREE.PlaneGeometry(4, 1.0), new THREE.MeshStandardMaterial({ map: this.resources.items.configurator, transparent: true, opacity: 1, depthWrite: false }))
        const advertisment = new THREE.Mesh(new THREE.PlaneGeometry(3.5, 0.7), new THREE.MeshStandardMaterial({ map: this.resources.items.advertisment, transparent: true, opacity: 1, depthWrite: false }))
        const modelling = new THREE.Mesh(new THREE.PlaneGeometry(4.5, 0.7), new THREE.MeshStandardMaterial({ map: this.resources.items.modelling, transparent: true, opacity: 1, depthWrite: false }))
        const educational = new THREE.Mesh(new THREE.PlaneGeometry(4.5, 0.5), new THREE.MeshStandardMaterial({ map: this.resources.items.educational, transparent: true, opacity: 1, depthWrite: false }))

        this.lighting = new THREE.SpotLight(0xffffff, 1000, 12, Math.PI / 2, 1, 1.5);
        this.lighting.target.position.set(-4, this.blackBorderY, 0);
        this.lightingHelper = new THREE.SpotLightHelper(this.lighting);
        this.lighting.castShadow = true;
        this.scene.add(this.lighting, this.lighting.target);

        this.scene.add(showcase, custom, portfolio, virtual, simulation, configurator, advertisment, modelling, educational)


        let scaleWidth = 1, scaleHeight = 1
        let bounds = this.getViewBounds(this.camera.instance, 13.1)
        console.log(bounds)
        scaleWidth = Math.min(bounds.width / 13, 1)
        scaleHeight = Math.min(bounds.width / 13, 1)
        this.scaleBall = Math.min(bounds.width / 13, 1)
        this.createBall(0.5 * scaleWidth, -1, 8, new THREE.Color('#' + colors[2]))

        if (this.sizes.width >= 200) {
            this.lighting.position.set(-4, this.blackBorderY + 10, -0.5);
            this.lighting.target.position.set(-4, this.blackBorderY, 0);

            showcase.position.set(-4, this.blackBorderY + 1, -0.05)
            showcase.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(2.4 * scaleWidth, 1.2 * scaleHeight, -2.0, 1, 0, new THREE.Color('#' + colors[0]), showcase, -1, 2.4, 0, -1.0, 2.1, 0)

            custom.position.set(2, this.blackBorderY + 1, -0.05)
            custom.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(6 * scaleWidth, 1.2 * scaleHeight, 2.3, 1, 0, new THREE.Color('#' + colors[2]), custom, 1, 1, 0, 1, 3.3, 0)

            portfolio.position.set(-2, this.blackBorderY + 2.2, -0.05)
            portfolio.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(3.0 * scaleWidth, 1.5 * scaleHeight, -0.7, 2.4, 0, new THREE.Color('#' + colors[1]), portfolio, -0.3, 4, 0, -0.7, 4.7, 0)

            virtual.position.set(-3, this.blackBorderY + 3.7, -0.05)
            virtual.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(3 * scaleWidth, 1.0 * scaleHeight, -1.0, 3.7, 0, new THREE.Color('#' + colors[0]), virtual, -1.0, 5.4, 0, -1.0, 6, 0)

            simulation.position.set(3, this.blackBorderY + 2.2, -0.05)
            simulation.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(1.9 * scaleWidth, 1.9 * scaleHeight, 2.8, 3.6, 0, new THREE.Color('#' + colors[1]), simulation, 2.8, 3.6, 0, 2, 1, 0)

            configurator.position.set(3, this.blackBorderY + 3.0, -0.05)
            configurator.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(3 * scaleWidth, 1.0 * scaleHeight, 2.8, 5.1, 0, new THREE.Color('#' + colors[2]), configurator, -4.8, 2.1, 0, 2.4, 5.6, 0)

            advertisment.position.set(0.8, this.blackBorderY + 4.5, -0.05)
            advertisment.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(3.5 * scaleWidth, 0.7 * scaleHeight, 0.8, 6, 0, new THREE.Color('#' + colors[1]), advertisment, -0.5, 7.2, 0, 0.8, 6.9, 0)

            modelling.position.set(-1.5, this.blackBorderY + 5.5, -0.05)
            modelling.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(3.8 * scaleWidth, 0.7 * scaleHeight, -4.3, 2.7, 0, new THREE.Color('#' + colors[2]), modelling, -3.8, 4.4, 0, -1.5, 1, 0)

            educational.position.set(5, this.blackBorderY + 6, -0.05)
            educational.scale.set(scaleWidth, scaleHeight, 1)
            this.createBox(4.5 * scaleWidth, 0.7 * scaleHeight, 3.6, 2.2, 0, new THREE.Color('#' + colors[0]), educational, 1.6, 6.3, 0, 3.6, 4.5, 0)

        }
    }


    createMouseBall() {
        let bounds = this.getViewBounds(this.camera.instance, 13.1)
        this.mouseBallRadius = 0.1;
        this.mouseBallBody = this.world.createBody({
            type: 'dynamic',
            position: this.Vec2(-3 - bounds.width / 2, this.blackBorderY + 2),
            bullet: true
        });
        this.mouseBallBody.createFixture(this.pl.Circle(this.mouseBallRadius), {
            density: 10000,
            friction: 0,
            restitution: 0.001
        });
        this.mouseBallBody.setGravityScale(0);
        const geometry = new THREE.CircleGeometry(0.1, 32);
        const material = new THREE.MeshBasicMaterial({ color: new THREE.Color('#000000'), transparent: true, opacity: 0, depthWrite: false });
        const mesh = new THREE.Mesh(geometry, material);
        this.scene.add(mesh);
        this.threeBalls.push({ mesh: mesh, body: this.mouseBallBody });
    }

    // * Mouse


    onMouseMove(event) {
        if (!this.mouseBallBody) {
            // console.log('Mouse ball body is not available.');
            return;
        }
        this.diggaX = (event.clientX / window.innerWidth) * 2 - 1;
        this.diggaY = -(event.clientY / window.innerHeight) * 2 + 1;

        this.raycaster = new THREE.Raycaster()
        this.raycaster.setFromCamera({ x: this.diggaX, y: this.diggaY }, this.experience.camera.instance)
        let intersects = this.raycaster.intersectObject(this.helperPlane)
        if (intersects.length > 0) {
            this.worldX = intersects[0].point.x
            this.worldY = intersects[0].point.y
        } else {
            this.worldX = -4 + this.diggaX * (this.rightBorder + 4);
            this.worldY = -15 + this.diggaY * 5;
        }
    }

    // * Touch

    onTouchStart(event) {
        // this.raycaster = new THREE.Raycaster()
        // this.diggaX = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
        // this.diggaY = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
        // this.raycaster.setFromCamera({ x: this.diggaX, y: this.diggaY }, this.experience.camera.instance)
        // let intersects = this.raycaster.intersectObject(this.helperPlane)
        // if (intersects.length > 0) {
        //     this.mouseBallBody.c_position.c.x = intersects[0].point.x
        //     this.mouseBallBody.c_position.c.y = intersects[0].point.y
        //     this.worldX = intersects[0].point.x
        //     this.worldY = intersects[0].point.y
        // } else {
        //     this.mouseBallBody.c_position.c.y = -4 + this.diggaX * (this.rightBorder + 4);
        //     this.mouseBallBody.c_position.c.y = -15 + this.diggaY * 5;
        //     this.worldX = -4 + this.diggaX * (this.rightBorder + 4);
        //     this.worldY = -15 + this.diggaY * 5;
        // }
    }

    activateCountdown() {
        if (this.updateAfterTime) {
            this.secondsLeft -= 1
            document.querySelector('#countdown').classList.add('active')
            document.querySelector('#countdown').innerHTML = this.secondsLeft
            if (this.secondsLeft > 0) {
                setTimeout(() => {
                    this.activateCountdown()
                }, 1000)
            } else {
                this.moveBackToStart()
                this.secondsLeft = 10
                document.querySelector('#countdown').innerHTML = '10'
                document.querySelector('#countdown').classList.remove('active')
            }
        }
    }

    moveBackToStart() {
        this.onReset = true
        this.deviation = 0
        this.threeBalls.forEach(ball => {
            if (ball.start && ball.second && ball.third) {
                let toX, toY, toRot
                if (this.towerVariant === 1) {
                    toX = ball.start.x
                    toY = ball.start.y
                    toRot = ball.start.rotation
                } else if (this.towerVariant === 2) {
                    toX = ball.second.x
                    toY = ball.second.y
                    toRot = ball.second.rotation
                } else if (this.towerVariant === 3) {
                    toX = ball.third.x
                    toY = ball.third.y
                    toRot = ball.third.rotation
                }
                ball.mesh.position.x += (toX - ball.mesh.position.x) * 0.05
                ball.mesh.position.y += (toY - ball.mesh.position.y) * 0.05
                ball.mesh.rotation.z += (toRot - ball.mesh.rotation.z) * 0.05
                this.deviation += Math.abs(ball.mesh.position.x - toX) + Math.abs(ball.mesh.position.y - toY) + Math.abs(ball.mesh.rotation.z - toRot)
                if (ball.texture) {
                    ball.texture.position.x = ball.mesh.position.x
                    ball.texture.position.y = ball.mesh.position.y
                    ball.texture.rotation.z = ball.mesh.rotation.z
                }
            } else if (ball.start) {
                ball.mesh.position.x += (ball.start.x - ball.mesh.position.x) * 0.05
                ball.mesh.position.y += (ball.start.y - ball.mesh.position.y) * 0.05
                ball.mesh.rotation.z += (ball.start.rotation - ball.mesh.rotation.z) * 0.05

            }

        })
        if (this.deviation > 1) {
            setTimeout(() => {
                this.moveBackToStart()
            }, 10)
        } else {
            this.towerVariant++
            if (this.towerVariant > 3) this.towerVariant = 1
            this.finishReset()
        }
    }

    finishReset() {
        this.threeBalls.forEach(ball => {
            ball.body.setPosition(this.Vec2(ball.mesh.position.x, ball.mesh.position.y))
            ball.body.setLinearVelocity(this.Vec2(0, 0))
            ball.body.setAngularVelocity(0)
            ball.body.setAngle(0)
        })

        setTimeout(() => {
            this.onReset = false
            this.updateAfterTime = false
        }, 100)
    }



    update() {
        // this.lightingHelper.update()
        // console.log(this.threeBalls)

        if(this.mouse.orientationActive) {
            document.getElementById('phone-svg').style.opacity = 1
            document.getElementById('phone-svg').style.display = "block"
        } else {
            document.getElementById('phone-svg').style.opacity = 0
            document.getElementById('phone-svg').style.display = "none"
        }

        if (this.world) {
            if (!this.onReset) {
                if (this.experience.time.delta > 0.001 && this.experience.time.delta < 0.4) {
                    // console.log(this.experience.time.delta)
                    this.world.step(this.experience.time.delta)

                }
            }
            this.world.setGravity(this.Vec2(Math.sin(this.mouse.leftToRight / 2 * Math.PI) * 50, -1 * Math.abs(Math.cos(this.mouse.leftToRight / 2 * Math.PI) * 20)))
            this.updateCount++
            this.moveDeviation = 0
            let toX, toY, toRot
            if (this.threeBalls.length > 1 && !this.onReset) {
                this.threeBalls.forEach((ball) => {
                    ball.mesh.position.x = ball.body.c_position.c.x
                    ball.mesh.position.y = ball.body.c_position.c.y
                    ball.mesh.rotation.z = ball.body.getAngle()
                    if (ball.texture) {
                        ball.texture.position.x = ball.mesh.position.x
                        ball.texture.position.y = ball.mesh.position.y
                        ball.texture.rotation.z = ball.mesh.rotation.z
                    }
                    if (this.updateCount > this.updateCountStep) {
                        if (ball.start && ball.second && ball.third) {
                            if (this.towerVariant === 1) {
                                toX = ball.start.x
                                toY = ball.start.y
                                toRot = ball.start.rotation
                            } else if (this.towerVariant === 2) {
                                toX = ball.second.x
                                toY = ball.second.y
                                toRot = ball.second.rotation
                            } else if (this.towerVariant === 3) {
                                toX = ball.third.x
                                toY = ball.third.y
                                toRot = ball.third.rotation
                            }

                            this.moveDeviation += Math.abs(ball.mesh.position.x - toX) + Math.abs(ball.mesh.position.y - toY) + Math.abs(ball.mesh.rotation.z - toRot)
                        }
                    }
                })
            }
            if (!this.updateAfterTime) {
                if (this.updateCount > this.updateCountStep) {
                    this.updateCountStep = this.updateCount + 100
                    if (this.moveDeviation > 40) {
                        // console.log('init update', this.moveDeviation)
                        this.updateAfterTime = true
                        this.secondsLeft = 10
                        this.activateCountdown()
                    }
                }
            }

            if (this.mouseBallBody) {
                const target = this.Vec2(this.worldX, this.worldY);

                // Constants for control
                const kP = 1;  // Proportional gain
                const kD = 0.002;   // Damping gain
                let body = this.mouseBallBody
                // Calculate the force to apply
                const positionError = target.sub(body.getPosition());
                const velocityError = body.getLinearVelocity().neg();

                // Proportional force (towards the target)
                const forceP = positionError.mul(kP);

                // Damping force (opposite of velocity)
                const forceD = velocityError.mul(kD);

                // Total force is the sum of proportional and damping forces
                let totalForce = forceP.add(forceD);
                totalForce = totalForce.mul(1000000)
                // console.log(totalForce)
                body.applyForce(totalForce, body.getPosition());

            }


        }
    }
}