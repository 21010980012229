import EventEmitter from "./EventEmitter"

export default class Sizes extends EventEmitter {

    constructor() {
        super()

        // Initial Setup
        if (window.innerWidth >= 750) {
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
        } else {
            this.width = window.innerWidth
            this.height = window.innerHeight * 1
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth >= 750) {
                this.width = window.innerWidth
                this.height = window.innerHeight
                this.pixelRatio = Math.min(window.devicePixelRatio, 2)
            } else {
                // if (window.innerHeight > this.height || window.innerHeight < this.height * 0.7 || window.innerWidth != this.width) {
                    this.width = window.innerWidth
                    this.height = window.innerHeight * 1
                    this.pixelRatio = Math.min(window.devicePixelRatio, 2)
                // }
            }

            this.trigger('resize')
        })
    }
}