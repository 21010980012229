import EventEmitter from './EventEmitter.js'
import * as THREE from 'three'

export default class Time extends EventEmitter {
    constructor() {
        super()

        // Initial Setup
        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.delta = 16

        this.clock = new THREE.Clock()
        this.elapsedTime = 0
        this.then = Date.now()

        window.requestAnimationFrame(() => {
            this.tick()
        })
    }

    tick() {
        window.requestAnimationFrame(() => {
            this.tick()
        })
        const currentTime = Date.now()
        this.delta = this.clock.getDelta()
        this.current = currentTime
        this.elapsed = this.current - this.start
        if (document.visibilityState === 'visible') {
            this.trigger('tick')
        }
    }
}