import * as THREE from 'three'
import gsap from 'gsap'

import Debug from './Utils/Debug.js'
import Time from './Utils/Time'
import Sizes from './Utils/Sizes'
import Camera from './Camera'
import Renderer from './Renderer'
import Resources from './Utils/Resources'

import sources from './sources.js'
import Mouse from './Utils/Mouse.js'

import Home from './World/Home.js'

export default class Experience {
    constructor(_canvas) {
        window.experience = this

        this.canvas = _canvas
        this.debug = new Debug(true)
        this.debug.active = true
        this.debug.ui.hide()

        this.time = new Time()
        this.sizes = new Sizes()
        this.mouse = new Mouse(this)

        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.loaded = false
        this.camera = new Camera(this, false)
        this.renderer = new Renderer(this)
        this.home = new Home(this)


        // Resize Event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // New Frame Event
        this.time.on('tick', () => {
            this.update()
        })


        this.resources.on('ready', () => {
            this.loaded = true
            setTimeout(() => {
                let svg = document.querySelector("#loading-svg");
                let svgBox = document.querySelector('.loading-icon')
                document.querySelector(".loading-overlay").classList.add("loaded");
                svgBox.classList.add("loaded");
                // svg.classList.add("loaded");
                this.stopRotation()

                setTimeout(() => {
                    let logoNav = document.querySelector('#logo-navbar')

                    gsap.to(svg, { rotate: 0, duration: 0.7, ease: "elastic.inOut(0.3,0.5)" });
                    setTimeout(() => {
                        let tl = gsap.timeline();
                        let scaleDown = logoNav.getBoundingClientRect().height / 100;
                        let sizeDiff = logoNav.getBoundingClientRect().height - 100;
                        let toLeft = logoNav.getBoundingClientRect().left - svg.getBoundingClientRect().left + sizeDiff / 2;
                        let toTop = logoNav.getBoundingClientRect().top - svg.getBoundingClientRect().top + sizeDiff / 2;

                        let textMitte = document.querySelector(".text-mitte");
                        let rightNav = document.querySelector(".right-part");
                        let companyNav = document.querySelector("#company-name-div");

                        tl.to(svg, { x: toLeft, y: toTop, scale: scaleDown, duration: 1.2, ease: "elastic.inOut(0.3,0.5)" });
                        tl.fromTo(document.querySelector(".loading-name"), { opacity: 1 }, { opacity: 0, duration: 0.2, ease: "power1.inOut" }, "<");
                        tl.fromTo(document.querySelector(".loading-overlay"), { opacity: 1 }, { opacity: 0, duration: 0.1, ease: "power2.out" }, "=-0.2");
                        tl.fromTo(textMitte, { opacity: 0, xPercent: -100 }, { opacity: 1, xPercent: 0, duration: 0.5, ease: "power2.out" });
                        tl.fromTo(rightNav, { opacity: 0, yPercent: -500, rotate: 30 }, { opacity: 1, yPercent: 0, rotate: 0, duration: 0.5, ease: "power2.out" }, "<");
                        tl.fromTo(companyNav, { opacity: 0, yPercent: -500, rotate: -30 }, { opacity: 1, yPercent: 0, rotate: 0, duration: 0.5, ease: "power2.out" }, "<");
                        setTimeout(() => {
                            document.querySelector(".loading-overlay").style.display = "none";
                            document.querySelector(".loading-icon").style.display = "none";
                            // console.log(svg.getBoundingClientRect().top)
                            // console.log(logoNav.getBoundingClientRect().top)
                        }, 2000)
                    }, 700)
                }, 50);
            }, 500)
        })
    }

    stopRotation() {
        const svgElement = document.getElementById('loading-svg');

        // Get computed style to determine the current transform
        const computedStyle = window.getComputedStyle(svgElement);
        const transformMatrix = computedStyle.getPropertyValue('transform');

        if (transformMatrix !== 'none') {
            // Extract the matrix values
            const values = transformMatrix.split('(')[1].split(')')[0].split(',');
            const a = values[0];
            const b = values[1];
            const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));

            // Set the transform directly to the current angle and stop the animation
            svgElement.style.animation = 'none';
            svgElement.style.transform = `rotate(${angle}deg)`;
            console.log(angle)


            // Force reflow to ensure the above styles are applied immediately
            svgElement.offsetHeight;

            // Add the loaded class to transition back to 0 degrees

            svgElement.classList.add('loaded');


            // svgElement.style.transform = `rotate(0deg)`;

            // svgElement.style.transform = `rotate(0deg)`;
        } else {
            // If no transform is applied (very unlikely in this case), just add the loaded class
            svgElement.classList.add('loaded');
            // svgElement.style.transform = `rotate(0deg)`;
        }
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        this.mouse.update()
        this.camera.update()
        this.renderer.update()
        this.home.update()
    }
}