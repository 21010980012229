import * as THREE from 'three'
import ammo from '../../libs/ammo.js'
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js"

export default class Softbody {
    constructor(experience) {
        this.experience = experience
        this.scene = this.experience.scene
        this.time = this.experience.time
        this.mouse = this.experience.mouse
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.ballMatcap = this.resources.items.ballMatcap

        this.clock = new THREE.Clock()


        this.Ammo
        this.softBodies = []
        this.rigidBodies = []
        this.threeObjects = []
        this.ballTarget = {}
        this.ballTarget.x = 0
        this.ballTarget.y = 3
        this.ballTarget.z = 0
        this.raycaster = new THREE.Raycaster()
        this.setFixedSize = false
        this.forceMultiplier = 60

        this.targetFPS = 60
        this.inShoot = false

        this.touchOccured = false


        this.initAmmo()
        this.sphereMaterial = new THREE.MeshMatcapMaterial({ matcap: this.ballMatcap, transparent: true, opacity: 0.1 })
        this.initHelperPlane()
        this.initEventlisteners()


        this.resize()

        this.sizes.on('resize', () => {
            this.resize()
        })
    }

    getViewBounds(camera, distance) {
        const vFov = camera.fov * Math.PI / 180; // Convert vertical fov to radians
        const height = 2 * distance * Math.tan(vFov / 2); // Height of the visible area at the distance
        const width = height * camera.aspect; // Width of the visible area at the distance
        // console.log('width: ', Math.abs(width), 'height: ', Math.abs(height))
        return {
            width: Math.abs(width),
            height: Math.abs(height)
        };
    }

    resize() {
        if (this.sizes.width <= 1200 || (this.sizes.width / this.sizes.height) < 1.262) {
            this.ballTarget.x = -4
            this.ballTarget.y = 1
            if (this.sizes.width <= 1200) {
                this.ballTarget.z = (this.sizes.height / 951)* (1.71994064e-15 * Math.pow(this.sizes.width, 6) - 7.69239073e-12 * Math.pow(this.sizes.width, 5) + 1.37553299e-08 * Math.pow(this.sizes.width, 4) - 1.24583901e-05 * Math.pow(this.sizes.width, 3) + 0.005918054 * Math.pow(this.sizes.width, 2) - 1.34446804 * this.sizes.width + 100.824302)
            } else {
                this.ballTarget.z = -1
            }



        } else {
            let bounds = this.getViewBounds(this.camera.instance, (-1 + this.sizes.width / 1920 - this.camera.instance.position.z))
            // this.ballTarget.x = 1.44e-6 * this.sizes.width ** 2 - 0.00325 * this.sizes.width + 0.919 + (950 - this.sizes.height) * 0.015 * (this.sizes.width / 1920)
            this.ballTarget.x = -4 + bounds.width / 4
            this.ballTarget.y = 3
            this.ballTarget.z = -1 + this.sizes.width / 1920
        }
        this.aimPlane.position.z = this.ballTarget.z;
    }

    async initAmmo() {
        this.Ammo = await ammo();
        ammo().then(() => {
            this.AmmoStart(this.Ammo)
        })
    }

    AmmoStart(Ammo) {
        this.tmpTrans = new Ammo.btTransform();

        const collisionConfiguration =
            new Ammo.btSoftBodyRigidBodyCollisionConfiguration();
        const dispatcher = new Ammo.btCollisionDispatcher(collisionConfiguration);
        const broadphase = new Ammo.btDbvtBroadphase();
        const solver = new Ammo.btSequentialImpulseConstraintSolver();
        const softBodySolver = new Ammo.btDefaultSoftBodySolver();
        this.physicsWorld = new Ammo.btSoftRigidDynamicsWorld(
            dispatcher,
            broadphase,
            solver,
            collisionConfiguration,
            softBodySolver
        );
        this.physicsWorld.setGravity(new Ammo.btVector3(0, 0, 0));
        this.physicsWorld.getWorldInfo().set_m_gravity(new Ammo.btVector3(0, 0, 0));
        this.softBodyHelpers = new Ammo.btSoftBodyHelpers();

        let volumeMass = 1;
        let widthSegments = 45;
        let heightSegments = 25;

        const sphereGeometry = new THREE.SphereGeometry(
            3.0,
            widthSegments,
            heightSegments
        );
        sphereGeometry.translate(1, 5, 0);
        this.createSoftVolume(sphereGeometry, volumeMass, 150);

        const pos = new THREE.Vector3();
        const quat = new THREE.Quaternion();
        pos.set(-20, 3, 0);
        quat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), (30 * Math.PI) / 180);
        this.createSphere(Math.random(), 32, 16, this.sphereMaterial, 200, pos, quat);
    }

    updatePhysics(deltaTime) {
        // Step worl
        this.physicsWorld.stepSimulation(deltaTime, 1);
        // Update soft bodies

        for (let i = 0, il = this.softBodies.length; i < il; i++) {
            let volume2 = this.softBodies[i];
            let geometry2 = volume2.geometry;
            let softBody2 = volume2.userData.physicsBody;
            let volumePositions = geometry2.attributes.position.array;
            let volumeNormals = geometry2.attributes.normal.array;
            let association = geometry2.ammoIndexAssociation;
            let numVerts = association.length;
            let nodes = softBody2.get_m_nodes();

            for (let j = 0; j < numVerts; j++) {
                let node = nodes.at(j);

                let nodePos = node.get_m_x();
                let x = nodePos.x();
                let y = nodePos.y();
                let z = nodePos.z();
                let nodeNormal = node.get_m_n();
                let nx = nodeNormal.x();
                let ny = nodeNormal.y();
                let nz = nodeNormal.z();

                let assocVertex = association[j];
                for (let k = 0, kl = assocVertex.length; k < kl; k++) {
                    let indexVertex = assocVertex[k];
                    volumePositions[indexVertex] = x;
                    volumeNormals[indexVertex] = nx;
                    indexVertex++;
                    volumePositions[indexVertex] = y;
                    volumeNormals[indexVertex] = ny;
                    indexVertex++;
                    volumePositions[indexVertex] = z;
                    volumeNormals[indexVertex] = nz;
                }
            }

            geometry2.attributes.position.needsUpdate = true;
            geometry2.attributes.normal.needsUpdate = true;
        }

        // Update rigid bodies
        for (let i = 0; i < this.rigidBodies.length; i++) {
            let objThree = this.rigidBodies[i];
            let objAmmo = objThree.userData.physicsBody;
            let ms = objAmmo.getMotionState();
            if (ms) {
                ms.getWorldTransform(this.tmpTrans);
                let p = this.tmpTrans.getOrigin();
                let q = this.tmpTrans.getRotation();
                objThree.position.set(p.x(), p.y(), p.z());
                objThree.quaternion.set(q.x(), q.y(), q.z(), q.w());
            }
        }
    }

    processGeometry(bufGeometry) {
        // Ony consider the position values when merging the vertices
        const posOnlyBufGeometry = new THREE.BufferGeometry();
        posOnlyBufGeometry.setAttribute(
            "position",
            bufGeometry.getAttribute("position")
        );
        posOnlyBufGeometry.setIndex(bufGeometry.getIndex());

        // Merge the vertices so the triangle soup is converted to indexed triangles
        const indexedBufferGeom =
            BufferGeometryUtils.mergeVertices(posOnlyBufGeometry);

        // Create index arrays mapping the indexed vertices to bufGeometry vertices
        this.mapIndices(bufGeometry, indexedBufferGeom);
    }

    isEqual(x1, y1, z1, x2, y2, z2) {
        const delta = 0.000001;
        return (
            Math.abs(x2 - x1) < delta &&
            Math.abs(y2 - y1) < delta &&
            Math.abs(z2 - z1) < delta
        );
    }

    mapIndices(bufGeometry, indexedBufferGeom) {
        // Creates ammoVertices, ammoIndices and ammoIndexAssociation in bufGeometry

        const vertices = bufGeometry.attributes.position.array;
        const idxVertices = indexedBufferGeom.attributes.position.array;
        const indices = indexedBufferGeom.index.array;

        const numIdxVertices = idxVertices.length / 3;
        const numVertices = vertices.length / 3;

        bufGeometry.ammoVertices = idxVertices;
        bufGeometry.ammoIndices = indices;
        bufGeometry.ammoIndexAssociation = [];

        for (let i = 0; i < numIdxVertices; i++) {
            const association = [];
            bufGeometry.ammoIndexAssociation.push(association);

            const i3 = i * 3;

            for (let j = 0; j < numVertices; j++) {
                const j3 = j * 3;
                if (
                    this.isEqual(
                        idxVertices[i3],
                        idxVertices[i3 + 1],
                        idxVertices[i3 + 2],
                        vertices[j3],
                        vertices[j3 + 1],
                        vertices[j3 + 2]
                    )
                ) {
                    association.push(j3);
                }
            }
        }
    }

    createSoftVolume(bufferGeom, mass, pressure) {
        this.processGeometry(bufferGeom);
        const soapMaterial = new THREE.MeshNormalMaterial({});
        const volume = new THREE.Mesh(bufferGeom, soapMaterial);
        volume.castShadow = false;
        volume.frustumCulled = false;
        this.scene.add(volume);

        // Volume physic object

        const volumeSoftBody = this.softBodyHelpers.CreateFromTriMesh(
            this.physicsWorld.getWorldInfo(),
            bufferGeom.ammoVertices,
            bufferGeom.ammoIndices,
            bufferGeom.ammoIndices.length / 3,
            true
        );

        const sbConfig = volumeSoftBody.get_m_cfg();
        sbConfig.set_viterations(20);
        sbConfig.set_piterations(20);

        // Soft-soft and soft-rigid collisions
        sbConfig.set_collisions(0x11);

        // Friction
        sbConfig.set_kDF(0.1);
        // Damping
        sbConfig.set_kDP(0.03);
        // Pressure
        sbConfig.set_kPR(pressure);
        // Stiffness
        volumeSoftBody.get_m_materials().at(0).set_m_kLST(1);
        volumeSoftBody.get_m_materials().at(0).set_m_kAST(1);
        volumeSoftBody.get_m_materials().at(0).set_m_kLST(1);

        volumeSoftBody.setTotalMass(mass, false);
        this.Ammo.castObject(volumeSoftBody, this.Ammo.btCollisionObject)
            .getCollisionShape()
            .setMargin(0.05);
        this.physicsWorld.addSoftBody(volumeSoftBody, 1, -1);
        volume.userData.physicsBody = volumeSoftBody;
        // Disable deactivation
        volumeSoftBody.setActivationState(1);

        this.softBodies.push(volume);
    }

    createRigidBody(threeObject, physicsShape, mass, pos, quat) {
        // console.log('object created')
        threeObject.position.copy(pos);
        threeObject.quaternion.copy(quat);

        const transform = new this.Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new this.Ammo.btVector3(pos.x, pos.y, pos.z));
        transform.setRotation(new this.Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
        const motionState = new this.Ammo.btDefaultMotionState(transform);

        const localInertia = new this.Ammo.btVector3(0, 0, 0);
        physicsShape.calculateLocalInertia(mass, localInertia);

        const rbInfo = new this.Ammo.btRigidBodyConstructionInfo(
            mass,
            motionState,
            physicsShape,
            localInertia
        );
        const body = new this.Ammo.btRigidBody(rbInfo);

        threeObject.userData.physicsBody = body;

        this.rigidBodies.push(threeObject);

        // Disable deactivation
        body.setActivationState(4);


        this.physicsWorld.addRigidBody(body);

        return body;
    }

    createRigidBodyShooter(threeObject, physicsShape, mass, pos, quat, resultingVector) {
        // console.log('object created')
        threeObject.position.copy(pos);
        threeObject.quaternion.copy(quat);

        const transform = new this.Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new this.Ammo.btVector3(pos.x, pos.y, pos.z));
        transform.setRotation(new this.Ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w));
        const motionState = new this.Ammo.btDefaultMotionState(transform);

        const localInertia = new this.Ammo.btVector3(0, 0, 0);
        physicsShape.calculateLocalInertia(mass, localInertia);

        const rbInfo = new this.Ammo.btRigidBodyConstructionInfo(
            mass,
            motionState,
            physicsShape,
            localInertia
        );
        const body = new this.Ammo.btRigidBody(rbInfo);

        threeObject.userData.physicsBody = body;

        this.rigidBodies.push(threeObject);

        // Disable deactivation
        body.setActivationState(4);

        // console.log('Activation State:', body.isActive());
        this.physicsWorld.addRigidBody(body);
        const impulse = new this.Ammo.btVector3(
            resultingVector.x * 0.01667,
            resultingVector.y * 0.01667,
            resultingVector.z * 0.01667
        );
        body.applyImpulse(
            impulse,
            new this.Ammo.btVector3(0, 0, 0)
        );

        // console.log('force applied')
        // console.log(body)

        return body;
    }

    createSphere(
        size,
        widthSegments,
        heightSegments,
        material,
        mass,
        pos,
        quat
    ) {
        const sphere = new THREE.Mesh(
            new THREE.SphereGeometry(size, widthSegments, heightSegments),
            material
        );
        const shapeSphere = new this.Ammo.btSphereShape(size);
        shapeSphere.setMargin(0.05);
        this.createRigidBody(sphere, shapeSphere, mass, pos, quat);
    }

    applyAttractionForce(softBody3, targetPoint, forceMagnitude) {
        let numNodes = softBody3.get_m_nodes().size();

        for (let i = 0; i < numNodes; i = i + 3) {
            let node = softBody3.get_m_nodes().at(i);
            let nodePos = node.get_m_x();

            // Calculate the vector from the node to the target point
            let toTarget = new this.Ammo.btVector3(
                targetPoint.x() - nodePos.x(),
                targetPoint.y() - nodePos.y(),
                targetPoint.z() - nodePos.z()
            );
            let distance = toTarget.length();
            if (distance > 2) {
                // Optionally, normalize to make the force independent of distance
                toTarget.normalize();

                toTarget.op_mul(forceMagnitude * 3); // Multiply the direction by the magnitude of the force

                // Apply the force to the node
                node.get_m_f().op_add(toTarget);
            }

            this.Ammo.destroy(toTarget); // Clean up to prevent memory leaks
        }
    }

    // * Balls

    initHelperPlane() {
        this.aimPlane = new THREE.Mesh(new THREE.PlaneGeometry(30, 15), new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true, opacity: 0, depthWrite: false }))
        this.aimPlane.position.y = 3;
        this.aimPlane.position.z = this.ballTarget.z;
        this.scene.add(this.aimPlane);
    }

    increaseSize(sphere, setFixedSize) {
        this.clickMomentTime = this.time.elapsed / 1000;
        let deltaTime = (this.clickMomentTime - this.clickStartTime) * 1.5;
        if (deltaTime > 1.5) deltaTime = 1.5;
        if (deltaTime < 0.3) deltaTime = 0.3;
        if (setFixedSize) this.sizeSphere = 1
        else this.sizeSphere = Math.log(deltaTime ** 0.5) + 1.5;
        sphere.scale.set(
            this.sizeSphere,
            this.sizeSphere,
            this.sizeSphere
        );

        if (this.inClick === 1) {
            setTimeout(() => {
                this.increaseSize(sphere, setFixedSize)
            }, 8);
        }
    }

    removeObject(object, scale) {
        if (scale > 0.04) {
            object.scale.set(scale, scale, scale)
            scale -= 0.03
            setTimeout(() => {
                this.removeObject(object, scale)
            }, 10)
        } else {
            this.scene.remove(object)
            this.physicsWorld.removeRigidBody(object.userData.physicsBody)
            if (object.geometry) {
                object.geometry.dispose();
            }

            if (object.material) {
                if (Array.isArray(object.material)) {
                    object.material.forEach(material => {
                        if (material.map) material.map.dispose();
                        material.dispose();
                    });
                } else {
                    if (object.material.map) object.material.map.dispose();
                    object.material.dispose();
                }
            }
            this.threeObjects.splice(this.threeObjects.indexOf(object), 1)
            this.rigidBodies.splice(this.rigidBodies.indexOf(object), 1)
        }
    }

    initEventlisteners() {
        document.addEventListener("touchstart", (event) => {
            if(this.inShoot) return

            if (this.mouse.scrollPos === 0) {
                this.inShoot = true
                this.touchOccured = true
                event.preventDefault();
                // console.log('touched')
                setTimeout(() => {
                    this.touchOccured = false
                }, 1000)
                this.sizeSphere = Math.random() * 0.3 + 0.7 + 0.5
                this.startPos = {}
                this.startPos.x = Math.random() >= 0.5 ? -4 - (Math.random() * 5 + 3) : -4 + (Math.random() * 5 + 2)
                this.startPos.y = Math.random() >= 0.5 ? 2.1 - (Math.random() * 5) : 2.1 + (Math.random() * 5)
                this.sphere = new THREE.Mesh(
                    new THREE.SphereGeometry(this.sizeSphere, 32, 16),
                    this.sphereMaterial
                );
                this.sphere.position.set(this.startPos.x, this.startPos.y, this.ballTarget.z);
                this.sphere.castShadow = false;
                this.sphere.name = this.time.elapsed / 1000;
                // console.log(this.sphere)
                this.scene.add(this.sphere);
                this.threeObjects.push(this.sphere);

                this.resultingVector = {}
                this.resultingVector.x = (this.ballTarget.x - this.startPos.x) * this.forceMultiplier;
                this.resultingVector.y = (this.ballTarget.y - this.startPos.y) * this.forceMultiplier;
                this.resultingVector.z = 0;
                const pos = new THREE.Vector3();
                const quat = new THREE.Quaternion();
                pos.set(this.startPos.x, this.startPos.y, this.ballTarget.z);
                quat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), (30 * Math.PI) / 180);
                let shapeSphere = new this.Ammo.btSphereShape(this.sizeSphere);
                shapeSphere.setMargin(0.05);
                this.createRigidBodyShooter(this.sphere, shapeSphere, 1, pos, quat, this.resultingVector);
                setTimeout(() => {
                    this.inShoot = false
                }, 800)
            }
        })

        document.addEventListener("mousedown", () => {
            if(this.inShoot) return
            console.log('clicked')
            if (!this.touchOccured) {
                // console.log('clicked')
                if (this.mouse.scrollPos === 0 && this.mouse.absY > 90 && this.mouse.absX > 60 && this.mouse.absX < this.sizes.width - 60) {
                    this.inShoot = true
                    this.inClick = 1
                    this.clickStartTime = this.time.elapsed / 1000;
                    this.raycaster.setFromCamera({ x: this.mouse.normX, y: this.mouse.normY }, this.camera.instance);
                    const intersects = this.raycaster.intersectObject(this.aimPlane);
                    this.startPos = {}
                    if (intersects.length > 0) {
                        this.startPos.x = intersects[0].point.x
                        this.startPos.y = intersects[0].point.y
                    }

                    this.sphere = new THREE.Mesh(
                        new THREE.SphereGeometry(1, 32, 16),
                        this.sphereMaterial
                    );

                    // console.log(Math.abs(this.startPos.x - this.ballTarget.x))
                    if ((Math.abs(this.startPos.x - this.ballTarget.x) < 3) && (Math.abs(this.startPos.y - this.ballTarget.y) < 3)) {
                        this.startPos.x = Math.random() >= 0.5 ? -7 - (Math.random() * 5 + 3) : -7 + (Math.random() * 5)
                        this.startPos.y = Math.random() >= 0.5 ? 2 - (Math.random() * 5 + 2) : 2 + (Math.random() * 5 + 2)
                        this.setFixedSize = true
                    }

                    this.sphere.position.set(this.startPos.x, this.startPos.y, this.ballTarget.z);
                    this.sphere.castShadow = false;
                    this.sphere.name = this.time.elapsed / 1000;
                    // console.log(this.sphere)
                    this.scene.add(this.sphere);
                    this.threeObjects.push(this.sphere);
                    this.increaseSize(this.sphere, this.setFixedSize);
                }
            }
        });


        document.addEventListener("mouseup", () => {
            if (this.mouse.scrollPos === 0 && this.inClick === 1) {
                this.inClick = 0
                this.clickEndTime = this.time.elapsed / 1000
                let deltaTime = (this.clickEndTime - this.clickStartTime) * 1.5
                if (deltaTime > 2.5) deltaTime = 2.5
                if (deltaTime < 0.3) deltaTime = 0.3
                if (this.setFixedSize) this.sizeSphere = 1
                else this.sizeSphere = Math.log(deltaTime ** 0.5) + 1.5
                this.endPos = {}
                this.endPos.x = (0 - this.startPos.x)
                this.endPos.y = (6 - this.startPos.y)
                this.resultingVector = {}
                this.resultingVector.x = (this.ballTarget.x - this.startPos.x) * this.forceMultiplier;
                this.resultingVector.y = (this.ballTarget.y - this.startPos.y) * this.forceMultiplier;
                this.resultingVector.z = 0;
                const pos = new THREE.Vector3();
                const quat = new THREE.Quaternion();
                pos.set(this.startPos.x, this.startPos.y, this.ballTarget.z);
                quat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), (30 * Math.PI) / 180);
                let shapeSphere = new this.Ammo.btSphereShape(this.sizeSphere);
                shapeSphere.setMargin(0.05);
                this.createRigidBodyShooter(this.sphere, shapeSphere, 1, pos, quat, this.resultingVector);
                setTimeout(() => {
                    this.inShoot = false
                }, 800)
            }
            this.setFixedSize = false
        });
    }

    update() {
        if (this.softBodies[0] && this.mouse.scrollPos < 1500) {
            this.applyAttractionForce(
                this.softBodies[0].userData.physicsBody,
                new this.Ammo.btVector3(this.ballTarget.x, this.ballTarget.y, this.ballTarget.z),
                0.01
            );
            if (this.physicsWorld) this.updatePhysics(this.clock.getDelta());
        }

        this.threeObjects.forEach((object) => {
            if (object.name !== 0) {
                if (object.position.y < -3 || object.position.y > 10 || object.position.x < -20 || object.position.x > 20) {
                    this.removeObject(object, object.scale.x)
                    object.name = 0
                } else if ((this.time.elapsed / 1000 - parseFloat(object.name)) > 10) {
                    // console.log('set to 0')
                    this.removeObject(object, object.scale.x)
                    object.name = 0
                }
            }
        })
    }
}